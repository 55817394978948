import React, { useState } from "react";
import {
  Grid,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useSnackbar } from "notistack";
import { DotPulse } from "@uiball/loaders";

const data = [
  {
    key: "0",
    name: "Exposición al sol",
    description: "Salida de manchas",
  },
  {
    key: "1",
    name: "Consumo de alcohol",
    description: "Granitos",
  },
  {
    key: "2",
    name: "Consumo de nuez",
    description: "Hinchazón",
  },
  {
    key: "3",
    name: "Consumo de lacteos",
    description: "Diarrea",
  },
  {
    key: "4",
    name: "Polvo",
    description: "Sinusitis",
  },
];

const Allergies = ({ rows = [], antecedentes = [] }) => {
  const [loading] = useState(false); //muestra de skeleton

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12} xl={12}>
          {loading ? (
            <Skeleton
              animation="wave"
              variant="rounded"
              width={250}
              height={250}
            />
          ) : (
            <>
              <TableContainer>
                <Table style={{ margin: "1.2em 0" }} size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell colSpan={2} align="center">
                        Alergias
                      </StyledTableCell>
                    </TableRow>

                    <TableRow>
                      <StyledTableCell>Nombre</StyledTableCell>
                      <StyledTableCell>Descripción</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((data) => (
                      <StyledTableRow key={data.key}>
                        <StyledTableCell component="th" scope="data">
                          {data.alergia}
                        </StyledTableCell>
                        <StyledTableCell>{data.descripcion}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={12} xl={12}>
          {loading ? (
            <Skeleton
              animation="wave"
              variant="rounded"
              width={250}
              height={250}
            />
          ) : (
            <>
              <TableContainer>
                <Table style={{ margin: "1.2em 0" }} size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <StyledTableCellAnt colSpan={3} align="center">
                        Antecedentes
                      </StyledTableCellAnt>
                    </TableRow>

                    <TableRow>
                      <StyledTableCellAnt>Nombre</StyledTableCellAnt>
                      <StyledTableCellAnt>Detalle</StyledTableCellAnt>
                      <StyledTableCellAnt>Descripción</StyledTableCellAnt>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {antecedentes.map((data) => (
                      <StyledTableRow key={data.key}>
                        <StyledTableCellAnt component="th" scope="data">
                          {data.antecedente}
                        </StyledTableCellAnt>
                        <StyledTableCellAnt component="th" scope="data">
                          {data.detalle}
                        </StyledTableCellAnt>
                        <StyledTableCellAnt>
                          {data.descripcion}
                        </StyledTableCellAnt>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Allergies;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0b6e89",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableCellAnt = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#004b80",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
