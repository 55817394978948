export default (date = null) => {
  if (!date) return <p style={{ color: "#9e00f02" }}>Fecha no definida 😵‍💫</p>;

  let fecha;

  try {
    fecha = date.toDate();
  } catch (error) {
    fecha = date;
  }
  try {
    const dia = fecha.getDate();
    const month = fecha.getMonth() + 1;
    const year = fecha.getFullYear();

    return `${year}-${month < 10 ? "0" + month : month}-${
      dia < 10 ? "0" + dia : dia
    }`;
  } catch (error) {
    return "Verificar información 🗓️";
  }
};
