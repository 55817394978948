import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Signs from "../Components/Signs";
import Allergies from "../Components/Allergies";
import Record from "../Components/Record";
import { useHistory } from "../hooks/useHistory";

const DetailsConsultas = () => {
  const { selectedConsulta } = useHistory();
  const [alergies, setAlergies] = useState([]);
  const [antecedentes, setAntecedente] = useState([]);

  const getAlergies = () => {
    const { paciente } = selectedConsulta;
    paciente.ref
      .collection("Alergias")
      .get()
      .then((response) => {
        const alergias = response.docs.map((doc) => {
          return { ...doc.data() };
        });

        setAlergies(alergias);
      });
    return;
  };

  const getAntecedents = () => {
    const { paciente } = selectedConsulta;
    paciente.ref
      .collection("Antecedentes")
      .get()
      .then((response) => {
        const antece = response.docs.map((doc) => {
          return { ...doc.data() };
        });

        setAntecedente(antece);
      });
    return;
  };

  useEffect(() => {
    if (selectedConsulta) {
      getAlergies();
      getAntecedents();
    }
  }, [selectedConsulta]);

  // const { consulta } = useDoctors();
  if (!selectedConsulta) return null;

  return (
    <>
      <Grid container style={design.root}>
        {/* <Grid item xs={12} md={6} xl={6}>
          <PersonalInformation />
        </Grid>*/}
        <Grid item xs={12} md={6} xl={6}>
          <Signs />
        </Grid> 
        <Grid item xs={12} md={6} xl={6}>
          <Allergies rows={alergies} antecedentes={antecedentes} />
        </Grid>
        <Grid item xs={12} md={12} xl={12}>
          <Record />
        </Grid>
      </Grid>
    </>
  );
};

export default DetailsConsultas;

const design = {
  root: {
    justifyContent: "center",
  },
  title: {
    color: "#9285c4",
    textAlign: "center",
    fontWeight: "bold",
    marginTop: 40,
  },
  select: {
    width: "95%",
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 20,
    borderBottomRightRadius: 20,
    borderTopRightRadius: 0,
  },
  button: {
    marginLeft: "42%",
    marginTop: 10,
    marginBottom: 10,
  },
};
