import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "../hooks/useHistory";
import { DoctorAsign } from "../../Components/DoctorAsign";
import { ServiceDate } from "../../Components/ServiceDate";
import { useDoctors } from "../hooks/useDoctors";

const Record = () => {
  const { selectedConsulta } = useHistory();
  const { consulta } = useDoctors();
  const tratamientoRef = useRef(null);

  useEffect(() => {
    // if (selectedConsulta && tratamientoRef) {
    //   tratamientoRef.current.innerHTML =
    //     tratamiento.replace("//n", "\n") || "Error al obtener";
    // }
  }, [selectedConsulta, tratamientoRef]);

  if (!selectedConsulta) {
    return null;
  }

  const {
    CIE10, //CODIGO_SUBGRUPO,Cap,Codigo,GRUPO,Subgrupo,
    analisis,
    applyProcedimients,
    estadoSalud,
    exploracionFisica,
    fechaAlta,
    interpretacion,
    laboratorio,
    padecimiento,
    procedimientos,
    recomendaciones,
    tratamiento,
  } = selectedConsulta?.PSOAP;
  console.log(selectedConsulta);
  return (
    <>
      <Grid container style={design.Tarjeta}>
        <Grid item xs={12} md={12} xl={12}>
          <h1 style={design.Titulo}>Nota médica</h1>
          <p style={design.texto}>
            <strong>Exploración física:</strong>{" "}
            {exploracionFisica || "Error al obtener"} <br />
            <strong>Padecimiento:</strong> {padecimiento || "Error al obtener"}{" "}
            <br />
            <strong>Diagnóstico:</strong>{" "}
            {CIE10.map((item, idx) => {
              return (
                <>
                  <b>{item?.CODIGO_SUBGRUPO}</b> {item?.GRUPO}{" "}
                  {item?.Subgrupo && <>- {item.Subgrupo}</>}
                </>
              );
            })}
            <br />
            <strong>Análisis:</strong> {analisis || "Error al obtener"}
            <br />
            <strong>Interpretación:</strong>{" "}
            {interpretacion || "Error al obtener"}
            <br />
            {laboratorio.length ? (
              <>
                <strong>Solicitud de estudios de laboratorio:</strong>{" "}
                <ul>
                  {laboratorio.map((item) => (
                    <li>{item?.nombre}</li>
                  ))}
                </ul>
              </>
            ) : null}
            {procedimientos ? (
              <>
                <strong>Procedimientos:</strong>{" "}
                {procedimientos || "Error al obtener"}
                <br />
              </>
            ) : null}
            <strong>Pronóstico y estado de salud:</strong>{" "}
            {estadoSalud || "Error al obtener"}
            <br />
            <strong>Tratamiento:</strong>{" "}
            <p>{tratamiento.replace("//n", "\n") || "Error al obtener"}</p>
            <strong>Recomendaciones:</strong>{" "}
            {recomendaciones || "Error al obtener"}
            <br />
            Generado por{" "}
            <strong>
              <DoctorAsign docReference={selectedConsulta?.Doctor} />{" "}
            </strong>
            el <ServiceDate date={fechaAlta?.toDate()} />
          </p>
        </Grid>
      </Grid>
    </>
  );
};

export default Record;

const design = {
  Tarjeta: {
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "5px 5px 15px #b4b4b4",
    border: "1px solid #a0410b",
    borderRadius: 15,
    padding: 10,
    marginTop: "3%",
    marginBottom: "3%",
    marginLeft: "5%",
    width: "90%",
  },
  clip: {
    height: "20px",
    borderRadius: 5,
    marginTop: -10,
    backgroundColor: "#a0410b",
  },
  Titulo: {
    fontSize: 28,
    color: "#a0410b",
    textAlign: "center",
  },
};
