import firebase from "../../../firebase";
export const searchUser = async (uid) => {
  const ref = firebase.db.collection("Usuarios").doc(uid);
  const response = await ref.get();

  if (!response.exists) {
    throw new Error("Usuario no econtrado.");
  }

  let doctorReference = null;

  const doctorResponse = await firebase.db.collection("Medicos").doc(uid).get();
  if (doctorResponse.exists) {
    return {
      id: response.id,
      ref: response.ref,
      doctorReference: doctorResponse.ref,
      ...response.data(),
    };
  }

  return { id: response.id, ref: response.ref, ...response.data() };
};
