import {
  Autocomplete,
  TextField,
  styled,
  lighten,
  darken,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import firebase from "../firebase/";

export const InsumosSearch = ({ onSelectValue = () => {} }) => {
  const procedimientosRef = useRef(null);

  const [servicios, setServicios] = useState([]);

  const [selectedProcess, setSelectedProcess] = useState(null);
  const get_Procedimientos = () => {
    firebase.db
      .collection("Services")
      .where("DEPARTAMENTO", "in", ["ServiciosMedicos"])
      // .where("DEPARTAMENTO", "==", "Farmacia")
      .orderBy("NOMBRE", "asc")
      .get()
      .then(async (response) => {
        const services = response.docs.map((doc) => {
          const { NOMBRE = "", DESCRIPCION = "" } = doc.data();
          const selectedName = NOMBRE || DESCRIPCION;
          const firstLetter = selectedName[0].toUpperCase();

          return {
            ...doc.data(),
            id: doc.id,
            ref: doc.ref,
            firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
          };
        });
        console.log(services);
        setServicios(services);
      })
      .catch((e) => console.log(e));
    return;
  };

  useEffect(() => {
    get_Procedimientos();
  }, []);

  return (
    <CssAutocomplete
      ref={procedimientosRef}
      // options={servicios}
      options={servicios.sort(
        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
      )}
      groupBy={(option) => option.firstLetter}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
      getOptionLabel={(option) => option?.NOMBRE || option.DESCRIPCION}
      onChange={(_, value) => {
        if (!value) {
          return setSelectedProcess(null);
        }
        const { NOMBRE, PRECIO, id, CLAVE,DESCRIPCION } = value;
        setSelectedProcess({ NOMBRE, PRECIO, DESCRIPCION, id, CLAVE });
        onSelectValue({ NOMBRE: NOMBRE ?? DESCRIPCION, PRECIO, id, CLAVE });
        setTimeout(() => {
          const ele = procedimientosRef.current.getElementsByClassName(
            "MuiAutocomplete-clearIndicator"
          )[0];
          if (ele) ele.click();
        }, 600);
      }}
      onKeyDown={(e) => {
        if (e.code === "Enter") {
          onSelectValue(selectedProcess);
          setSelectedProcess(null);
          const ele = procedimientosRef.current.getElementsByClassName(
            "MuiAutocomplete-clearIndicator"
          )[0];
          if (ele) ele.click();
        }
      }}
      renderInput={(params) => <TextField {...params} label="Procedimiento" />}
    />
  );
};

const CssAutocomplete = styled(Autocomplete)({
  width: "90%",
  margin: 10,
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 20,
    borderBottomRightRadius: 20,
    borderTopRightRadius: 0,
    borderWidth: 3,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#0b6e89",
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 20,
      borderBottomRightRadius: 20,
      borderTopRightRadius: 0,
      borderWidth: 3,
    },
    "&:hover fieldset": {
      borderColor: "yellow",
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 20,
      borderBottomRightRadius: 20,
      borderTopRightRadius: 0,
      borderWidth: 3,
    },
    "&.Mui-focused fieldset": {
      borderColor: "green",
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 20,
      borderBottomRightRadius: 20,
      borderTopRightRadius: 0,
      borderWidth: 3,
    },
  },
});

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});
