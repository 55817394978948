import { useEffect, useState } from "react";

export const DoctorAsign = ({ docReference = null }) => {
  const [name, setName] = useState(null);

  useEffect(() => {
    try {
      if (!docReference) return;
      docReference.get().then((response) => {
        if (!response.exists) {
          return;
        }

        const { nombre } = response.data();

        setName(nombre);
      });
    } catch (error) {
      setName("Verificar información 🤯");
    }
  }, [docReference]);
  if (!name) return  <p style={{color:'#9e0f02'}} >Sin seguimiento 🥺</p>;
  return name;
};
