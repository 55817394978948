import app from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/database";
import "firebase/messaging";
import firebaseConfig from "./config";

class Firebase {
  constructor() {
    try {
      if (!app.apps.length) {
        app.initializeApp(firebaseConfig);
        // app.initializeApp(conf)
      }
      this.db = app.firestore();
      this.auth = app.auth();
      this.storage = app.storage(); //.ref();
      this.database = app.database();
      this.messaging = app.messaging();
    } catch (error) {
      console.log(error);
    }
  }
}
const firebase = new Firebase();
export default firebase;
