import { Button } from "@mui/material";
import { useState } from "react";
import firebase from "../firebase";
import algoliasearch from "algoliasearch";

const client = algoliasearch("CJ6E1JY7AY", "9adb6e43cc80593a82a0cff3d47756ef");
const index = client.initIndex("PACIENTES");
export const Downloads = () => {
  const [results, setResults] = useState([]);
  const getData = async () => {
    const response = await firebase.db
      .collection("Pacientes")
      .orderBy("name")
      .get();
    console.log(response.size);
    // setResults(
    const array = response.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });

    const uniqueIds = [];

    const unique = array.filter((element) => {
      const isDuplicate = uniqueIds.includes(element.name);

      if (!isDuplicate) {
        uniqueIds.push(element.id);

        return true;
      }

      return false;
    });

    setResults(unique);
    // );
  };

  const uploadToAlgolia = async () => {
    const saveObjects = results.map((doc) => {
      const { name, id, fechaNacimiento } = doc;
      const date = fechaNacimiento.toDate().getTime();
      return { name, id, fechaNacimiento: date };
    });
    console.log("Uploading...");
    console.log(saveObjects);
    await index.saveObjects(saveObjects, {
      autoGenerateObjectIDIfNotExist: true,
    });
    alert("Uplaod completed");
    return;
  };
  return (
    <div>
      <Button onClick={getData}>Get Data</Button>
      <Button onClick={uploadToAlgolia}>Upload to Algolia</Button>
      {results.map((doc, idx) => {
        return (
          <p>
            #{idx} {doc?.name || doc?.id}
          </p>
        );
      })}
    </div>
  );
};
