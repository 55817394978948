import { useState } from "react";
import { HistoryContext } from "./HistoryContext";

const HistoryProvider = ({ children }) => {
  const [selectedConsulta, setSelectedConsulta] = useState(null);

  return (
    <HistoryContext.Provider value={{ setSelectedConsulta, selectedConsulta }}>
      {children}
    </HistoryContext.Provider>
  );
};

export default HistoryProvider;
