import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import firebase from "../../firebase";
import { DoctorAsign } from "../../Components/DoctorAsign";
import timestamp_toString from "../../utils/timestamp_toString";
import { timestampToString } from "../../utils/transformDates";
import _ from "lodash";
import { NurseAsign } from "../../Components/NurseAsign";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { ResponsableInfo } from "../../Components/ResponsableInfo";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function Reportes() {
  const [columns, setColumns] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState([]);

  React.useEffect(() => {
    getDoctores().then(({ consultas, columns }) => {
      //   console.log(consultas);
      setData(consultas);
      setColumns(columns);
    });
  }, []);
  return (
    <>
      {/* <DataGridPremium columns={columns} rows={data} /> */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((title) => {
                return <TableCell>{title.headerName}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.pxName}
                </TableCell>
                <TableCell component="th" scope="row">
                  <DoctorAsign docReference={row.Doctor} />
                </TableCell>
                <TableCell>{timestampToString(row?.date)}</TableCell>
                <TableCell>
                  <NurseAsign docReference={row?.enfermera} />
                </TableCell>
                <TableCell>
                  <ResponsableInfo docReference={row?.responsable} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
const getDoctores = async () => {
  return new Promise(async (resolve, reject) => {
    const response = await firebase.db
      .collectionGroup("Consultas")
      .where("PSOAP", "==", null)
      .get();
    const consultas = response.docs.map(async (doc) => {
      const [COLLECTION, ID] = doc.ref.parent.parent.path.split("/");
      const PXResponse = await firebase.db.collection(COLLECTION).doc(ID).get();

      return {
        ...doc.data(),
        time: doc.data().date.toMillis(),
        pxName: PXResponse.data().name,
        // doctorName: doctorResponse.data().nombre,
        // fechaString: timestampToString(doc.data().date),
        // enfermeraName: enfermeraReponse.data().nombre,
        // recepcion: recepcionResponse.data().nombre,
        id: doc.id,
      };
    });

    const columns = [
      //   { field: "id", headerName: "ID", width: 90 },
      {
        field: "pxName",
        headerName: "Nombre Paciente",
        width: 400,
        editable: true,
      },
      {
        field: "doctorName",
        headerName: "Nombre Doctor",
        width: 400,
        editable: true,
      },
      {
        field: "fechaString",
        headerName: "Fecha Consulta",
        width: 150,
        editable: false,
      },
      {
        field: "enfermeraName",
        headerName: "Enfermera",
        width: 400,
        editable: false,
      },
      {
        field: "recepcion",
        headerName: "Recepcionista",
        width: 400,
        editable: false,
      },
    ];

    Promise.all(consultas).then((r) => {
      resolve({ consultas: _.sortBy(r, ["time"], ["desc"]), columns });
    });
  });
};
