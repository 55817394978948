import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

const Redirection = () => {
  const { usuario, user } = useAuth();
  
  if (!user) return <Navigate replace to={"/login"} />;
  if (!usuario?.area) return null;
  return <Navigate replace to={`/${usuario?.area}`} />;
};

export default Redirection;
