import { useEffect, useReducer, useState } from "react";
import AuthContext from "./authContext";
import AuthReducer from "./authReducer";
import firebase from "../../firebase";
import {
  SET_LOADING,
  USER_DATA,
  USER_LOGIN,
  USER_LOGOUT,
  SET_CONSULTAS,
} from "./types";
import { useSnackbar } from "notistack";
import authHandlerError from "./controllers/authHandlerError";
import { searchUser } from "./controllers/searchUser";

const initialState = {
  usuario: null,
  user: null,
  isLoading: true,
  consultas: [],
  consultas_Count: 0,
};

const AuthState = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const [loading, setLoading] = useState(true);
  const login = async (email, password) => {
    await dispatch({ type: SET_LOADING, payload: true });
    try {
      const userCredentials = await firebase.auth.signInWithEmailAndPassword(
        email,
        password
      );

      if (!userCredentials.user.emailVerified) {
        enqueueSnackbar("Correo electrónico no verificado.");
        firebase.auth.signOut();
      }
      const usuario = await searchUser(userCredentials.user.uid);
      await dispatch({ type: USER_DATA, payload: usuario });
      await dispatch({ type: SET_LOADING, payload: false });

      enqueueSnackbar(`Bienvenido ${usuario?.DatosPersonales.nombre}`, {
        variant: "success",
      });
      return usuario.area;
    } catch (error) {
      const text = authHandlerError(error);
      firebase.auth.signOut();
      enqueueSnackbar(text, { variant: "warning" });
      dispatch({ type: SET_LOADING, payload: false });
      return null;
    }
  };

  useEffect(() => {
    firebase.auth.onAuthStateChanged((user) => {
      if (user) {
        searchUser(user?.uid).then((usuario) => {
          dispatch({ type: USER_DATA, payload: usuario });
        });
      }
    });
  }, []);

  const logout = async () => {
    try {
      await dispatch({ type: SET_LOADING, payload: true });
      await firebase.auth.signOut();
      await dispatch({ type: USER_LOGOUT });
      await dispatch({ type: SET_LOADING, payload: false });
    } catch (error) {
      enqueueSnackbar("Ocurrio un error al cerrar la sesión");
    }
  };

  useEffect(() => {
    firebase.auth.onAuthStateChanged((currentUser) => {
      dispatch({ type: USER_LOGIN, payload: currentUser });
      dispatch({ type: SET_LOADING, payload: false });
      if (currentUser) {
        // if (!state.isLoading)
        searchUser(currentUser.uid)
          .then((usuario) => dispatch({ type: USER_DATA, payload: usuario }))
          .catch(() => logout());
      }
    });
  }, []);

  const search_Consultas_Today = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    firebase.db
      .collectionGroup("Consultas")
      .where("date", ">=", today)
      .onSnapshot((snap) => {
        setLoading(true);
        setTimeout(() => {
          const cons = snap.docs.map(async (doc) => {
            try {
              const response = await doc.ref.parent.parent.get();

              if (doc.data().status === "end") {
                return null;
              }

              return {
                ref: doc.ref,

                consultaRef: doc.ref,
                pxReference: response.ref,
                ...response.data(),
                ...doc.data(),
              };
            } catch (error) {
              return null;
            }
          });

          Promise.all(cons).then((r) => {
            //

            dispatch({
              type: SET_CONSULTAS,
              payload: r
                .filter(Boolean)
                .sort((val) => val.date.toMillis())
                .sort((val) => {
                  if (val.status === "payment") {
                    return -1;
                  }

                  if (val.status === "onQuery") {
                    return 1;
                  }

                  return 0;
                }),
            });
            setLoading(false);
          });
        }, 2500);
        // .then(() => setIsLoading(false));
        // .then(resolve);
      });

    return;
  };

  const search_Consultas = async () => {
    firebase.db
      .collectionGroup("Consultas")
      .where("status", "!=", "end")
      .orderBy("status", "desc")
      .onSnapshot((snap) => {
        setLoading(true);
        setTimeout(() => {
          const cons = snap.docs.map(async (doc) => {
            try {
              const response = await doc.ref.parent.parent.get();
              return {
                ref: doc.ref,

                consultaRef: doc.ref,
                pxReference: response.ref,
                ...response.data(),
                ...doc.data(),
              };
            } catch (error) {
              return null;
            }
          });

          Promise.all(cons).then((r) => {
            dispatch({ type: SET_CONSULTAS, payload: r.filter(Boolean) });
            setLoading(false);
          });
        }, 2500);
        // .then(() => setIsLoading(false));
        // .then(resolve);
      });
  };

  const get_ConsultasCount = () => {
    try {
      firebase.db
        .collectionGroup("Consultas")
        .where("responsable", "==", state.usuario.ref)
        .get()
        .then((response) => {
          dispatch({ type: "CONSULTAS_COUNT", payload: response.size });
        });
    } catch (error) {
      dispatch({ type: "CONSULTAS_COUNT", payload: "_err" });
    }
  };

  const [catalogo, setCatalogo] = useState([]);

  const getCatalogo = async (departamento) => {
    const resp = await firebase.db
      .collection("Services")
      .where("DEPARTAMENTO", "==", departamento)
      .orderBy("NOMBRE", "desc")
      .get();

    const arr = resp.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
      ref: doc.ref,
    }));

    setCatalogo(arr);
    return;
  };

  const sendNewSolicitud = async (details, almacen) => {
    await firebase.db.collection("Solicitudes").add({
      details,
      date: new Date(),
      responsable: state.usuario.ref,
      almacen,
      attended: false,
    });

    enqueueSnackbar("Solicitud enviada!", { variant: "success" });
    return;
  };

  const [patient, setPatient] = useState(null);
  const searchPx = async (id) => {
    try {
      console.log(id);
      await setPatient(null);
      const response = await firebase.db.collection("Pacientes").doc(id).get();

      if (!response.exists) {
        enqueueSnackbar("No pudimos encontrar más registro de este paciente");
        return;
      }

      setTimeout(() => {
        setPatient({ ...response.data(), id, ref: response.ref });
      }, 1000);
      enqueueSnackbar("Paciente seleccionado", { variant: "info" });
      return;
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "warning" });
      console.log(error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        patient,
        firebase,
        catalogo,
        loading,
        logout,
        login,
        get_ConsultasCount,
        getCatalogo,
        searchPx,
        search_Consultas,
        search_Consultas_Today,
        sendNewSolicitud,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthState;
