export default ({ code, message }) => {
  switch (code) {
    case "auth/wrong-password":
      return "Correo o contraseña incorrectos. ";
    case "auth/invalid-email":
      return "Correo no válido, intentalo de nuevo.";
    default:
      return message;
  }
};
