import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { Pinwheel } from "@uiball/loaders";

export const ProtectedRoute = ({ children }) => {
  const { user, isLoading, usuario } = useAuth();

  if (isLoading)
    return (
      <>
        <div style={{ marginTop: '20%', marginLeft: "45%" }}>
          <Pinwheel size={80} color="#004b80" />
        </div>
      </>
    );
  if (!user) return <Navigate to="/login" />;

  // if(user)return <Navigate to={`/${usuario?.area}`} />
  return <>{children}</>;
};
