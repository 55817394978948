const firebaseConfig = {
  apiKey: "AIzaSyA7zfFh3fbn5rndpIWUFR_dbMTy9oz1eDY",
  authDomain: "duran-pbe.firebaseapp.com",
  projectId: "duran-pbe",
  storageBucket: "duran-pbe.appspot.com",
  messagingSenderId: "772521180489",
  appId: "1:772521180489:web:544f4cc0ad7cd976d0ee98",
  measurementId: "G-KZJ2PB7B4C",
};

export default firebaseConfig;
