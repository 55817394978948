import { Skeleton, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDoctors } from "../../03-DOCTORS/hooks/useDoctors";
import timestamp_toString from "../../utils/timestamp_toString";

const PersonalInformation = () => {
  const { consulta, isLoading } = useDoctors();

  const [doc, setDoc] = useState({
    folio: 1,
    DatosCliente: {
      correo: "dulce.mg.19@gmail.com",
      folio: "12",
      nombre: "Leonardo Marcial Herrera",
      telefono: "5573938939",
      fechaNacimiento: "10/12/1998",
      edad: 30,
      sexo: "Masculino",
      tipoSangre: "A+",
      donador: "No",
      religion: "No",
      nacionalidad: "Mexicana",
      estadocivil: "Soltero(a)",
      ocupacion: "Estudiante",
      origen: "Texcoco",
      direccion: "Fray Pedro de Gante 320, col. San Lorenzo, Texcoco,México",
    },
    Orden: [],
    descuento: 0,
    subtotal: 0,
    total: 100,
    responsable: "",
    fechaAlta: "08/12/2022",
  });

  useEffect(() => {
    setDoc({ ...doc, DatosCliente: { ...consulta?.paciente } });
  }, [consulta]);

  if (isLoading)
    return (
      <Skeleton
        animation="wave"
        variant="rounded"
        width={400}
        height={40}
        style={{ marginBottom: 20 }}
      />
    );

  const DireccionPx = () => {
    try {
      if (typeof doc.DatosCliente.direccion === "string") {
        return doc.DatosCliente.direccion;
      }
      return `${doc.DatosCliente.direccion?.calle} ${
        doc.DatosCliente.direccion?.colonia
      } ${doc.DatosCliente.direccion?.cp} ${
        doc?.DatosCliente?.direccion?.municipio || ""
      } ${doc.DatosCliente.direccion?.estado}`;
    } catch (error) {
      return "Desconocido";
    }
  };
  return (
    <>
      <Grid container style={design.Tarjeta}>
        <Grid xs={4} md={4} xl={4}></Grid>
        <Grid xs={4} md={4} xl={4} style={design.clip}></Grid>
        <Grid xs={4} md={4} xl={4}></Grid>
        <Grid xs={12} md={12} xl={12}>
          <h2 id="folio" style={design.Titulo}>
            {doc.DatosCliente?.name || "Desconocido"}
          </h2>
          <p style={design.fol}>Id cliente: {doc.DatosCliente.folio}</p>
        </Grid>

        <Grid item xs={12} md={7} xl={7}>
          <p style={design.texto}>
            <strong>Fecha de nacimiento:</strong>{" "}
            {timestamp_toString(doc.DatosCliente?.fechaNacimiento) ||
              "Desconocido"}{" "}
            - {doc.DatosCliente?.edad || "Sin obtener"} años
            <br />
            <strong>Sexo:</strong> {doc.DatosCliente?.sexo || "Desconocido"}
            <br /> <strong>Tipo sangre:</strong>{" "}
            {doc.DatosCliente?.tipoSangre || "Desconocido"}
            <br />
            <strong>Donador de órganos:</strong>{" "}
            {doc.DatosCliente?.organos || "Desconocido"}
            <br />
            <strong>Nacionalidad:</strong>{" "}
            {doc.DatosCliente?.nacionalidad || "Desconocido"}
            <br />
            <strong>Origen:</strong> {doc.DatosCliente?.origen || "Desconocido"}
          </p>
        </Grid>

        <Grid item xs={12} md={5} xl={5}>
          <p style={design.texto}>
            <strong>Correo:</strong> {doc.DatosCliente?.correo || "Desconocido"}
            <br />
            <strong>Teléfono:</strong>{" "}
            {doc.DatosCliente?.telefono || "Desconocido"}
            <br />
            <strong>Ocupación:</strong>{" "}
            {doc.DatosCliente?.ocupacion || "Error al obtener"}
            <br />
            <strong>Estado civil:</strong>{" "}
            {doc.DatosCliente?.estadocivil || "Desconocido"}
            <br />
            <strong>Religión:</strong>{" "}
            {doc.DatosCliente?.religion || "Desconocido"}
          </p>
        </Grid>

        <Grid item xs={12} md={12} xl={12}>
          <p style={design.texto}>
            <strong>Dirección:</strong> <DireccionPx />
          </p>
        </Grid>
      </Grid>
    </>
  );
};

export default PersonalInformation;

const design = {
  Tarjeta: {
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "5px 5px 15px #b4b4b4",
    border: "1px solid #A89dcf",
    borderRadius: 15,
    padding: 10,
    marginTop: "3%",
    marginBottom: "3%",
    marginLeft: "5%",
    width: "90%",
  },
  clip: {
    height: "20px",
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 20,
    borderBottomRightRadius: 20,
    borderTopRightRadius: 0,
    borderWidth: 2,
    marginTop: -10,
    backgroundColor: "#9285c4",
  },
  Titulo: {
    fontSize: 28,
    color: "#9285c4",
    textAlign: "center",
  },
  fol: {
    textAlign: "center",
  },
};
