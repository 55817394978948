import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  lighten,
  darken,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { DotPulse } from "@uiball/loaders";
import {
  CheckBoxOutlineBlank,
  Delete,
  Vaccines,
  AssignmentInd,
  Science,
} from "@mui/icons-material";
import { useDoctors } from "../hooks/useDoctors";
import { movicareLab } from "../../Apis/articlesLab";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { RecetaTemplate } from "../../templates/Receta";
import { SearchCIE10 } from "./SearchCIE10";
import { InsumosSearch } from "../../Components/InsumosSearch";

const SOAP = () => {
  const { consulta, doctorDetails, finalizar_Consulta } = useDoctors();

  const [otroLab, setOtroLab] = useState(false);

  const [loading, setLoading] = useState(false); //muestra de skeleton
  const [loadingCIE10, setLoadingCIE10] = useState(false); //muestra de skeleton
  const [loadingProcess, setLoadingProcess] = useState(false); //muestra de skeleton
  const { error } = useState(false); //Utilizar impresión de manejo de errores
  const [form, setForm] = useState({
    motivo: "",
    padecimiento: "",
    CIE10_group: ["", ""],
    CIE10_subgroup: ["", ""],
    newSigns: {
      temperatura: 0,
      tension_sistolica: 0,
      tension_diastolica: 0,
      peso: 0,
      talla: 0,
      frecuencia_cardiaca: 0,
      frecuencia_respiratoria: 0,
      oximetria: 0,
      nota: "",
    },
    sistemas: [{ sistema: "", nota: "" }],
    interpretacion: "",
    exploracionFisica: "",
    laboratorio: [],
    analisis: "",
    cie10: [],
    estadoSalud: "",
    tratamiento: "",
    recomendaciones: "",
    antecedentes: "",
  });

  const [items, setItems] = useState([]);
  const getItems = () => {
    movicareLab
      .get("art/articulos/X1mFXnBjf48uFNWFB9ow/lz8BzO5udd3bzsjJNZDr")
      .then((response) => JSON.parse(response.data.articulos))
      .then((items) => {
        setItems(items);
      });
  };
  // AGRUPAR ESTUDIOS LAB
  const options = items.map((option) => {
    const firstLetter = option.nombre[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
  const [procedimientos, setProcedimiento] = useState([]);
  const handleProcedimientos = (value) => {
    console.log(value);
    const { NOMBRE, DESCRIPCION } = value;

    setProcedimiento((prev) => [...prev, NOMBRE ?? DESCRIPCION]);
    console.log(value);
  };

  const removeProcedimiento = (position) => {
    const newValue = procedimientos.splice(position, 1);
    // setProcedimiento((prev) => prev.splice(position, 1));
  };

  useEffect(() => getItems(), []);

  const [spin, setSpin] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const [articulosSelected, selectArticulos] = useState([]);
  const [CIE10, setCIE10] = useState([]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} xl={6}>
          <CssTextField
            label="Padecimiento actual"
            required
            type="text"
            multiline
            maxRows={4}
            variant="outlined"
            helperText={error ? "Verificar información" : null}
            value={form.padecimiento}
            onChange={(value) =>
              setForm({
                ...form,
                padecimiento: value.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} md={6} xl={6}>
          <CssTextField
            label="Exploración fisica"
            type="text"
            required
            multiline
            maxRows={4}
            variant="outlined"
            helperText={error ? "Verificar información" : null}
            value={form.exploracionFisica}
            onChange={(value) =>
              setForm({
                ...form,
                exploracionFisica: value.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} md={6} xl={6}>
          <CssTextField
            label="Análisis"
            type="text"
            required
            multiline
            maxRows={4}
            variant="outlined"
            helperText={error ? "Verificar información" : null}
            value={form.analisis}
            onChange={(value) =>
              setForm({
                ...form,
                analisis: value.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} md={6} xl={6}>
          <CssTextField
            label="Interpretación de estudios"
            type="text"
            multiline
            maxRows={4}
            variant="outlined"
            helperText={error ? "Verificar información" : null}
            value={form.interpretacion}
            onChange={(value) =>
              setForm({
                ...form,
                interpretacion: value.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} md={6} xl={6}>
          <SearchCIE10
            onSelect={(value) => {
              setCIE10((prev) => [...prev, value]);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <TableContainer>
            <Table
              sx={{ marginBottom: 5, width: "93%" }}
              size="small"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>Grupo</StyledTableCell>
                  <StyledTableCell>Subgrupo</StyledTableCell>
                  <StyledTableCell>Acción</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingCIE10 ? (
                  <DotPulse />
                ) : (
                  CIE10.map((data, idx) => (
                    <StyledTableRow key={idx}>
                      <StyledTableCell component="th" scope="data">
                        {data.GRUPO}
                      </StyledTableCell>
                      <StyledTableCell>{data.Subgrupo}</StyledTableCell>
                      <StyledTableCell>
                        <Delete
                          onClick={() => {
                            CIE10.splice(idx, 1);
                            loadingCIE10(true);
                            setTimeout(() => setLoadingCIE10(false), 900);
                          }}
                          fontSize="small"
                          style={design.icon}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} md={6} xl={6}>
          <FormControlLabel
            control={
              <Checkbox
                color="info"
                // icon={<CheckBoxOutlineBlank />}
                checkedIcon={<Science />}
                onChange={(_, b) => {
                  setOtroLab(b);
                }}
              />
            }
            label="Estudios especiales"
          />
          {otroLab ? (
            <CssTextField
              label="Solicitud de estudios de laboratorio"
              type="text"
              variant="outlined"
              helperText={error ? "Verificar información" : null}
              onKeyDown={(e) => {
                if (e.code.toUpperCase().includes("ENTER")) {
                  selectArticulos([
                    ...articulosSelected,
                    { nombre: e.target.value, precio: 0 },
                  ]);
                  setOtroLab(false)
                }
              }}
              // value={"form.estudiosLaboratorio"}
            />
          ) : (
            <Autocomplete
              disablePortal
              id="Laboratorio"
              loading={!items.length}
              // options={items}
              options={options.sort(
                (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
              )}
              groupBy={(option) => option.firstLetter}
              renderGroup={(params) => (
                <li key={params.key}>
                  <GroupHeader>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              )}
              getOptionLabel={(option) => option.nombre}
              onChange={(_, target) => {
                const { nombre, precio } = target;
                selectArticulos([...articulosSelected, { nombre, precio }]);
              }}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  label="Solicitud de estudios de laboratorio"
                  type="text"
                  variant="outlined"
                  helperText={error ? "Verificar información" : null}
                  value={"form.estudiosLaboratorio"}
                />
              )}
            />
          )}
          {items.nombre === "otro" ? (
            <CssTextField
              label="Solicitud de estudios de laboratorio"
              type="text"
              variant="outlined"
              helperText={error ? "Verificar información" : null}
              value={"form.estudiosLaboratorio"}
            />
          ) : null}
        </Grid>

        <Grid item xs={12} md={6} xl={6}>
          <TableContainer>
            <Table
              sx={{ marginBottom: 5, width: "93%" }}
              size="small"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StyledTableCellLab>Estudio</StyledTableCellLab>
                  <StyledTableCellLab>Acción</StyledTableCellLab>
                </TableRow>
              </TableHead>
              <TableBody>
                {spin ? (
                  <DotPulse />
                ) : (
                  articulosSelected.map((data, idx) => (
                    <StyledTableRow key={idx}>
                      <StyledTableCellLab component="th" scope="data">
                        {data.nombre}
                      </StyledTableCellLab>
                      <StyledTableCellLab>
                        <Delete
                          onClick={() => {
                            setSpin(true);
                            articulosSelected.splice(idx, 1);
                            setTimeout(() => {
                              setSpin(false);
                            }, 900);
                          }}
                          fontSize="small"
                          style={design.icon}
                        />
                      </StyledTableCellLab>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Procedimeintos Tables */}
        <Grid item xs={12} md={6} xl={6}>
          <FormControlLabel
            control={
              <Checkbox
                color="warning"
                icon={<CheckBoxOutlineBlank />}
                checkedIcon={<Vaccines />}
                onChange={(_, b) => {
                  setForm((prev) => ({ ...prev, applyProcediments: b }));
                }}
              />
            }
            label="Aplicación procedimiento"
          />
          {form.applyProcediments && (
            <InsumosSearch onSelectValue={handleProcedimientos} />
          )}
          {form.applyProcediments === "otro" ? (
            <CssTextField
              label="Aplicación procedimiento"
              type="text"
              variant="outlined"
              helperText={error ? "Verificar información" : null}
              value={"form.procedimiento"}
            />
          ) : null}
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <TableContainer>
            <Table
              sx={{ marginBottom: 5, width: "93%" }}
              size="small"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StyledTableCellProcess>Procedimiento</StyledTableCellProcess>
                  <StyledTableCellProcess>Acción</StyledTableCellProcess>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {console.log(procedimientos)} */}
                {loadingProcess ? (
                  <DotPulse />
                ) : (
                  procedimientos.map((data, idx) => (
                    <StyledTableRow key={idx}>
                      <StyledTableCellProcess component="th" scope="data">
                        {data}
                      </StyledTableCellProcess>

                      <StyledTableCellProcess>
                        <Delete
                          onClick={() => {
                            setLoadingProcess(true);
                            removeProcedimiento(idx);
                            setTimeout(() => setLoadingProcess(false), 900);
                          }}
                          fontSize="small"
                          style={design.icon}
                        />
                      </StyledTableCellProcess>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {/* ---------------------- */}

        <Grid item xs={12} md={6} xl={6}>
          <CssTextField
            label="Pronóstico y estado de salud"
            type="text"
            multiline
            maxRows={4}
            variant="outlined"
            helperText={error ? "Verificar información" : null}
            value={form.estadoSalud}
            onChange={(value) =>
              setForm({
                ...form,
                estadoSalud: value.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} md={6} xl={6}>
          <CssTextField
            label="Tratamiento"
            type="text"
            style={{ whiteSpace: "pre-line" }}
            multiline
            required
            maxRows={4}
            variant="outlined"
            helperText={"Tratamiento a imprimir en la receta"}
            // value={form.tratamiento}
            onChange={(value) =>
              // console.log(value.target.value)
              setForm({
                ...form,
                tratamiento: value.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} md={6} xl={6}>
          <CssTextField
            label="Recomendaciones"
            required
            type="text"
            multiline
            maxRows={4}
            variant="outlined"
            helperText={"Recomendaciones a imprimir en la receta"}
            value={form.recomendaciones}
            onChange={(value) =>
              setForm({
                ...form,
                recomendaciones: value.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          {consulta && form.analisis && form.tratamiento && CIE10.length && (
            <PDFDownloadLink
              disabled={true}
              document={
                <RecetaTemplate
                  data={{
                    ...form,
                    consulta,
                    treatmentList: form.tratamiento.split("\n"),
                    CIE10,
                    laboratorio: articulosSelected,
                    doctorDetails,
                  }}
                />
              }
              fileName={`${consulta.paciente.name}.pdf`}
            >
              {/* {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')} */}
              <CustomButton startIcon={<AssignmentInd fontSize="medium" />}>
                Imprimir receta médica
              </CustomButton>
            </PDFDownloadLink>
          )}
        </Grid>
        {spinner ? (
          <div style={design.center2}>
            <DotPulse size={40} color="#0b2f89" />
          </div>
        ) : (
          <Button
            onClick={() => {
              const {
                analisis,
                estadoSalud = "",
                exploracionFisica = "",
                padecimiento,
                recomendaciones = "",
                tratamiento = "",
                interpretacion = "",
                applyProcediments = null,
              } = form;
              setSpinner(true);
              finalizar_Consulta({
                applyProcediments,
                analisis,
                estadoSalud,
                exploracionFisica,
                padecimiento,
                procedimientos,
                recomendaciones: recomendaciones.replace("\n", "//n"),
                interpretacion,
                tratamiento: tratamiento.replace("\n", "//n"),
                laboratorio: articulosSelected,
                CIE10,
              }).then(() => setSpinner(false));
            }}
            variant="contained"
            style={design.button}
          >
            Guardar
          </Button>
        )}
      </Grid>
    </>
  );
};

export default SOAP;

const design = {
  button: {
    marginTop: 15,
    marginBottom: 10,
    width: "95%",
  },
  center: {
    marginTop: 26,
    marginLeft: "40%",
  },
  center2: {
    marginTop: 26,
    marginLeft: "46%",
  },
  icon: {
    color: "#7e0c01",
  },
};

const CssTextField = styled(TextField)({
  width: "90%",
  marginTop: 10,
  marginBottom: 10,
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 20,
    borderBottomRightRadius: 20,
    borderTopRightRadius: 0,
    borderWidth: 2,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#0b6e89",
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 20,
      borderBottomRightRadius: 20,
      borderTopRightRadius: 0,
      borderWidth: 2,
    },
    "&:hover fieldset": {
      borderColor: "yellow",
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 20,
      borderBottomRightRadius: 20,
      borderTopRightRadius: 0,
      borderWidth: 2,
    },
    "&.Mui-focused fieldset": {
      borderColor: "green",
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 20,
      borderBottomRightRadius: 20,
      borderTopRightRadius: 0,
      borderWidth: 2,
    },
  },
});

const CustomButton = styled(Button)(() => ({
  justifyContent: "center",
  alignItems: "center",
  padding: "15px 25px",
  gap: "10px",
  width: "90%",
  height: "66px",
  margin: "5%",
  border: "5px solid #14a37f",
  fontFamily: "Poppins",
  fontWeight: "bold",
  color: "#14a37f",
  borderRadius: "15px",
  "&:hover": {
    border: "5px solid #14a37f",
    backgroundColor: "#14a37f",
    color: "#fff",
    fontFamily: "Poppins",
    fontWeight: "bold",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#004b80",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableCellLab = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00675e",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableCellProcess = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#cd5500",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});
