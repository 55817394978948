import React, { useLayoutEffect, useState } from "react";
import { Grid, Skeleton, styled, Tabs, Tab } from "@mui/material";
import {
  Sick,
  Person,
  MedicalInformation,
  FolderShared,
} from "@mui/icons-material";
import PersonalInformation from "../../Components/Doctors/PersonalInformation";
import GeneralData from "../Components/GeneralData";
import Signs from "../Components/Signs";
import PSOAP from "../Components/PSOAP";
import History from "../Components/History";
import { useDoctors } from "../hooks/useDoctors";
import { useNavigate } from "react-router-dom";
import { DotPulse } from "@uiball/loaders";
import DetailsConsultas from "./DetailsConsultas";
import HistoryProvider from "../Context/HistoryContext/HistoryProvider";

const Consulta = () => {
  const { consulta } = useDoctors();
  const navigate = useNavigate();
  const { loading } = useState(false); //muestra de skeleton
  const [isLoading, setIsLoading] = useState(false);
  // JUEGO VISUAL DE CAMBIOS DE TAB
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <p>{children}</p>}
      </div>
    );
  }

  useLayoutEffect(() => {
    return () => {
      if (!consulta) {
        navigate("/");
      }
      setIsLoading(false);
    };
  }, []);

  if (isLoading) {
    return (
      <div style={{ marginTop: 100, alignContent: "center", flex: 1 }}>
        <DotPulse />
      </div>
    );
  }
  return (
    <>
      <Grid container style={design.root}>
        <Grid item xs={12} md={12} xl={12}>
          {loading ? (
            <Skeleton animation="wave" variant="text" width={40} height={40} />
          ) : (
            <h1 style={design.title}>Consulta médica</h1>
          )}
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          {loading ? (
            <Skeleton animation="wave" variant="text" width={40} height={40} />
          ) : (
            // Ready
            <PersonalInformation />
          )}
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          {loading ? (
            <Skeleton animation="wave" variant="text" width={40} height={40} />
          ) : (
            // Ready
            <Signs />
          )}
        </Grid>
        <Grid item xs={11} md={11} xl={11}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="fullWidth"
            centered
          >
            <AntTab
              icon={<Person />}
              iconPosition="start"
              label="PSOAP"
              wrapped
              index={0}
            />
            {/* <AntTab
              icon={<Sick />}
              iconPosition="start"
              label="Signos"
              wrapped
              index={2}
            /> */}
            {/* <AntTab
              icon={<MedicalInformation />}
              iconPosition="start"
              label="PSOAP"
              wrapped
              index={1}
            /> */}
            <AntTab
              icon={<FolderShared />}
              iconPosition="start"
              label="Historial clínico"
              wrapped
              index={1}
            />
          </Tabs>
          <TabPanel value={value} index={0}>
            {/* INFORMACION GENERAL DE LA CONSULTA */}
            <GeneralData />

            {/* AQUI SE INSERTA TODA LA INFORMACION DE LA CONSULTA */}
            <PSOAP />
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            <UpdateSigns />
          </TabPanel> */}
          <TabPanel value={value} index={1}>
            <HistoryProvider>
              <History />
              <DetailsConsultas />
            </HistoryProvider>
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
};

export default Consulta;

const design = {
  root: {
    justifyContent: "center",
  },
  title: {
    color: "#9285c4",
    textAlign: "center",
    fontWeight: "bold",
    marginTop: 40,
  },
  select: {
    width: "95%",
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 20,
    borderBottomRightRadius: 20,
    borderTopRightRadius: 0,
  },
  button: {
    marginLeft: "42%",
    marginTop: 10,
    marginBottom: 10,
  },
};

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    backgroundColor: "#efefef",
    "&.Mui-selected": {
      color: "purple",
      fontWeight: theme.typography.fontWeightMedium,
      border: "2px solid purple",
      backgroundColor: "#e5cce5",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);
