import React, { useState } from "react";
import algolia from "algoliasearch";
import { Button } from "@mui/material";
import firebase from "../firebase";
import { useSnackbar } from "notistack";
const client = algolia("CJ6E1JY7AY", "9adb6e43cc80593a82a0cff3d47756ef");
const index = client.initIndex("CATALOGO");

/**
 *
 * @param {[]} inputArray
 * @returns []
 */
// const createChunks = (inputArray) => {
//   const perChunk = 500; // items per chunk

//   const result = inputArray.reduce((resultArray, item, index) => {
//     const chunkIndex = Math.floor(index / perChunk);

//     if (!resultArray[chunkIndex]) {
//       resultArray[chunkIndex] = []; // start a new chunk
//     }

//     resultArray[chunkIndex].push(item);

//     return resultArray;
//   }, []);
//   return result;
// };

const UpdateAlgolia = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStaus] = useState(null);

  const [catalogo, setCatalogo] = useState([]);

  const getCatalogo = async () => {
    const response = await firebase.db.collection("CatalogoAux").get();

    const arr = response.docs.map((item) => {
      const {
        nombre,
        clave,
        departamento,
        subcategoria,
        subdepartamento,
        sustanciaActiva,
        descripcion,
        precio_con_iva,
        codeBar,
      } = item.data();
      return {
        objectID: item.id,
        codeBar,
        clave,
        nombre,
        precio: precio_con_iva,
        descripcion,
        sustanciaActiva,
        departamento,
        subcategoria,
      };
    });

    return arr;
  };

  const saveInAlgolia = async (value) => {
    await index.saveObjects(value);
    return true;
  };

  const handlePress = async () => {
    try {
      setStaus("Getting data...");
      const catalogo = await getCatalogo();
      console.log(catalogo);
      setStaus("Uploading data...");
      await saveInAlgolia(catalogo);

      setStaus(null);
      enqueueSnackbar("Finish!", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Error!", { variant: "error" });
    }
  };

  //   const getPacientes = async () => {
  //     const response = await firebase.db
  //       .collection("Pacientes")
  //       .orderBy("name")
  //       .get();

  //     return response.docs.map((doc) => {
  //       const { name, fechaNacimiento } = doc.data();

  //       const date = fechaNacimiento?.toMillis() || new Date().getTime();

  //       return { name, id: doc.id, fechaNacimiento: date, objectID: doc.id };
  //     });
  //   };
  //   ;
  //   const sincronizeData = async () => {
  //     try {
  //       setStaus("Getting users");
  //       const pacientes = await getPacientes();
  //       setStaus("Creating chunks...");
  //       const chunks = createChunks(pacientes);
  //       console.log(chunks);

  //       setStaus("Upload data to Algolia");
  //       chunks.forEach((chunk) => {
  //         index.saveObjects(chunk, {
  //           autoGenerateObjectIDIfNotExist: true,
  //         });
  //       });

  //       setStaus(null);
  //       enqueueSnackbar("Data sycing succesfull");
  //     } catch (error) {
  //       console.log(error);
  //       enqueueSnackbar(error.message, { variant: "error" });
  //       setStaus(null);
  //     }
  //   };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Button onClick={handlePress} disabled={status}>
        {status ?? "Sycing"}
      </Button>
    </div>
  );
};

export default UpdateAlgolia;
