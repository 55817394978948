import React, { useState, useEffect } from "react";
import {
  Grid,
  Skeleton,
  TextField,
  Autocomplete,
  Button,
  styled,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  IconButton,
  TablePagination,
  tableCellClasses,
} from "@mui/material";
import { Inbox, Delete } from "@mui/icons-material";
import { DotPulse } from "@uiball/loaders";
import { useNavigate } from "react-router";
import { useAuth } from "../hooks/useAuth";
import CatalogoComponent from "../05-STORE/Components/CatalogoComponent";
import { useSnackbar } from "notistack";
import SearchItems from "../06-PHARMACY/Components/SearchItems";

const CreatePetitionItem = () => {
  const { sendNewSolicitud, usuario } = useAuth();

  const { enqueueSnackbar } = useSnackbar();
  const [list, setList] = useState([]);

  const [loading, setLoading] = useState(false); //muestra de skeleton
  const [isLoading, setIsLoading] = useState(false); //carga table
  const [spin] = useState(false);
  const [selectedAlmacen, setSelectedAlmacen] = useState(null);
  const { error } = useState(false); //Utilizar impresión de manejo de errores
  const navigate = useNavigate();

  const [form, setForm] = useState({
    concepto: "",
    clave: "",
    cantidad: 0,
    fechaAlta: "",
    responsable: "",
  });

  // Table Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddItem = () => {
    try {
      const { nombre, id, cantidad } = form;

      // if (!selectedAlmacen) {
      //   throw Error("Selecciona un almacen.");
      // }
      if (!cantidad || cantidad < 0) {
        throw Error("Cantidad no valida, verifica.");
      }

      if (!id) {
        throw Error("Vuelve a seleccionar un articulo.");
      }

      setList([...list, { nombre, id, cantidad }]);

      setForm({
        concepto: "",
        clave: "",
        cantidad: 0,
        fechaAlta: "",
        responsable: "",
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "warning" });
    }
  };

  const handleDelete = (position) => {
    setIsLoading(true);
    list.splice(position, 1);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };

  return (
    <>
      <Grid container style={design.root}>
        <Grid item xs={12} md={12} xl={12}>
          <h1 style={design.title}>Solicitud almacén general</h1>
        </Grid>
        {/* <AlmacenesSelect
          disabled={selectedAlmacen}
          onSelect={setSelectedAlmacen}
        /> */}
        <Grid item xs={12} md={8} xl={8}>
          {loading ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={40}
              height={40}
            />
          ) : (
            <SearchItems
              onSelectValue={({ nombre, id }) =>
                setForm({ ...form, nombre, id })
              }
            />
          )}
        </Grid>
        <Grid item xs={12} md={3} xl={4}>
          {loading ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={40}
              height={40}
            />
          ) : (
            <CssTextField
              label="Cantidad"
              variant="outlined"
              type="number"
              helperText={error ? "Verificar información" : null}
              value={form.cantidad}
              onChange={(value) =>
                setForm({
                  ...form,
                  cantidad: parseInt(value.target.value) ?? 0,
                })
              }
              onKeyDown={(e, d) => {
                if (e.code.toUpperCase().includes("ENTER")) {
                  handleAddItem();
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        </Grid>

        <Grid item xs={12} md={12} xl={12}>
          <TableContainer style={design.Table}>
            <Table size="small" stickyHeader>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Cantidad</StyledTableCell>
                  <StyledTableCell>Concepto</StyledTableCell>
                  <StyledTableCell>Eliminar</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <StyledTableCell colSpan={4} align="center">
                    <LinearProgress style={{ width: "100%", margin: 20 }} />
                  </StyledTableCell>
                ) : null}

                {(rowsPerPage > 0
                  ? list.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : list
                ).map((doc, idx) => (
                  <CustomRow
                    key={idx}
                    {...doc}
                    onDelete={() => handleDelete(idx)}
                  />
                ))}

                {list.length && !isLoading ? null : (
                  <>
                    <StyledTableRow>
                      <StyledTableCell colSpan={4} align="center">
                        <Inbox fontSize="large" color="warning" />
                        <h1 style={{ textAlign: "center" }}>
                          Sin insumos añadidos
                        </h1>
                      </StyledTableCell>
                    </StyledTableRow>
                  </>
                )}
              </TableBody>
              <StyledTableRow>
                <StyledTableCell align="center" colSpan={5}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={list.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </StyledTableCell>
              </StyledTableRow>
            </Table>
          </TableContainer>
          <p style={{ textAlign: "center" }}>
            Filas totales: 1 - {list.length}
          </p>

          {spin ? (
            <div style={design.center}>
              <DotPulse size={40} color="#0b2f89" />
            </div>
          ) : (
            <Button
              onClick={() => {
                sendNewSolicitud(list, usuario?.almacen ?? usuario.area).then(
                  () => {
                    setTimeout(() => window.location.reload(), 1200);
                  }
                );
              }}
              style={design.button}
              variant="contained"
              size="large"
            >
              Enviar solicitud
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const CustomRow = ({ cantidad, nombre, onDelete = () => {} }) => {
  const [loading, setLoading] = useState(true);
  if (!loading) {
    return <Skeleton />;
  }
  return (
    <StyledTableRow>
      <StyledTableCell align="center">{cantidad}</StyledTableCell>
      <StyledTableCell align="center">{nombre}</StyledTableCell>
      <StyledTableCell align="center">
        <IconButton onClick={() => onDelete()}>
          <Delete style={{ color: "#9e0f02" }} />
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default CreatePetitionItem;

const design = {
  root: {
    margin: 20,
  },
  title: {
    color: "#0b6e89",
    textAlign: "center",
    fontWeight: "bold",
    marginTop: 40,
  },
  button: {
    marginLeft: "20%",
    marginTop: 15,
    marginBottom: 10,
    width: "60%",
  },
  center: {
    marginTop: 20,
    marginLeft: "46%",
  },
  Table: {
    width: "96%",
    marginLeft: "1%",
    border: "1px solid #0b6e89",
    borderRadius: 10,
    marginTop: "5%",
    marginBottom: "5%",
    maxHeight: 400,
  },
};

const CssTextField = styled(TextField)({
  width: "90%",
  margin: 10,
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 20,
    borderBottomRightRadius: 20,
    borderTopRightRadius: 0,
    borderWidth: 3,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#0b6e89",
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 20,
      borderBottomRightRadius: 20,
      borderTopRightRadius: 0,
      borderWidth: 3,
    },
    "&:hover fieldset": {
      borderColor: "yellow",
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 20,
      borderBottomRightRadius: 20,
      borderTopRightRadius: 0,
      borderWidth: 3,
    },
    "&.Mui-focused fieldset": {
      borderColor: "green",
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 20,
      borderBottomRightRadius: 20,
      borderTopRightRadius: 0,
      borderWidth: 3,
    },
  },
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#dcdcdc",
    transition: "0.2s",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0b6e89",
    color: theme.palette.common.white,
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "55%",
  },
}));

const insumos = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
  { title: "Forrest Gump", year: 1994 },
  { title: "Inception", year: 2010 },
  { title: "City of God", year: 2002 },
  { title: "Se7en", year: 1995 },
  { title: "The Silence of the Lambs", year: 1991 },
  { title: "The Great Dictator", year: 1940 },
  { title: "Cinema Paradiso", year: 1988 },
  { title: "The Lives of Others", year: 2006 },
  { title: "Grave of the Fireflies", year: 1988 },
  { title: "Paths of Glory", year: 1957 },
  { title: "Django Unchained", year: 2012 },
  { title: "The Shining", year: 1980 },
  { title: "WALL·E", year: 2008 },
  { title: "American Beauty", year: 1999 },
  { title: "The Dark Knight Rises", year: 2012 },
  { title: "Inglourious Basterds", year: 2009 },
  { title: "Snatch", year: 2000 },
  { title: "3 Idiots", year: 2009 },
  { title: "Monty Python and the Holy Grail", year: 1975 },
];

const listInsumos = ["AGUJA 5 ML", "ALGODONES"];
