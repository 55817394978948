export const timestampToString = (date) => {
  try {
    const fecha = date.toDate();

    const day = fecha.getDate();
    const month = fecha.getMonth();
    const year = fecha.getFullYear();
    return `${day < 10 ? "0" + day : day} / ${meses[month]}/ ${year}`;
  } catch (error) {
    return "date_error"
  }
};

const meses = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
