import {
  Page,
  Text,
  Document,
  StyleSheet,
  View,
  Image,
  Svg,
  Line,
} from "@react-pdf/renderer";
import { DoctorAsign } from "../Components/DoctorAsign";
import { timestampToString } from "../utils/transformDates";

const styles = StyleSheet.create({
  /* Padding arriba derecha abajo izquierda en dirección de las agujas del reloj */
  imgHead: {
    width: "20%",
    padding: "10px",
    marginHorizontal: "25%",
  },
  background: {
    position: "absolute",
    minWidth: "100%",
    minHeight: "100%",
    display: "block",
    height: "100%",
    width: "100%",
  },
  table: {
    // borderTop: "2px solid #000",
    width: "100%",
    textAlign: "right",
    padding: "0px 80px 0px 0px",
  },
  title: {
    textAlign: "center",
    fontSize: 20,
    color: "#000000",
    fontWeight: "bold",
    padding: "10px 80px 0px 0px",
  },
  tableInfo: {
    // border: "2px solid #356c2d",
    marginHorizontal: 30,
    marginVertical: 10,
    display: "flex",
    flexDirection: "columnn",
    backgroundRepeat: "repeat",
    backgroundImage: "url(https://movicaremx.com/HFUTURA/HF-OPACITY.png)",
  },
  txt: {
    marginHorizontal: 10,
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  subtitle: {
    fontWeight: "bold",
    color: "#000000",
    marginVertical: 10,
    fontSize: 13,
  },
  names: {
    fontWeight: "bold",
    color: "#000000",
    // marginVertical: 10,
    fontSize: 12,
  },
  tablesSigns: {
    // border: "2px solid #000",
    marginVertical: 5,
    lineHeight: "100%",
    letterSpacing:"-0.2px"
  },
  tableDetails: {
    // border: "2px solid #000",
    display: "flex",
    flexDirection: "row",
    flex: "1 1 1",
    justifyContent: "space-between",
    padding: "7px",
    lineHeight: "160%",
  },
  tableContent: {
    // border: "2px solid #000",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "5px 10px",
  },
  signature: {
    width: "100%",
    textAlign: "center",
    marginHorizontal: 30,
    marginTop: 30,
    padding: "10px 80px 0px 0px",
    textTransform: "none",
  },
  footer: {
    display: "flex",
    flexDirection: "columnn",
    justifyContent: "flex-end",
    position: "absolute",
    bottom: 0,
    // marginVertical: 20,
    // marginHorizontal: 20,
  },
});

export const RecetaTemplate = ({ data }) => {
  const {
    recomendaciones = "",
    consulta,
    CIE10,
    laboratorio = [],
    tratamiento,
    doctorDetails,
    treatmentList,
  } = data;
  const lab =
    laboratorio?.map(({ nombre }) => {
      return (
        <>
          <Text style={{ marginHorizontal: 8 }}>-</Text>
          <Text>
            {" "}
            {nombre}
            {"\n"}
          </Text>
        </>
      );
    }) || [];

  const diagnostico = CIE10?.map(({ GRUPO, Subgrupo }) => {
    return (
      <>
        <Text style={{ marginHorizontal: 8 }}>•</Text>
        <Text>
          {" "}
          {GRUPO} {Subgrupo ? "-" : null} {Subgrupo}
          {"\n"}
        </Text>
      </>
    );
  });
  const treatment = treatmentList.map((item, idx) => {
    const indicaciones = item.split("-");
    return (
      <>
        <Text>
          {" "}
          {idx + 1}.- {indicaciones[0]}
          {"\n"}
          <Text style={{ marginHorizontal: 12 }}>{indicaciones[1]}</Text>
          {"\n"}
        </Text>
        {"\n"}
      </>
    );
  });
  return (
    <Document>
      <Page
        size="A4"
        style={{ fontSize: 9, color: "#080808", lineHeight: "110%" }}
      >
        <Image
          source="https://movicaremx.com/HFUTURA/HF.png"
          style={styles.imgHead}
        />
        <Svg height={20}>
          <Line
            color="#093046"
            stroke="4"
            fill="#093046"
            strokeWidth="3"
            visibility="visible"
            x1="0" //Punto inicial en x
            y1="0" //Punto inicial en y
            x2="800" //punto final en x
            y2="0" //Punto final en y
          />
        </Svg>
        <View style={styles.table}>
          <Text>
            Dr. &nbsp;
            <DoctorAsign docReference={consulta.Doctor} />
          </Text>
          <Text>Medicina General</Text>
          <Text>Ced.Profesional: {doctorDetails?.cedula}</Text>
          <Text>{doctorDetails?.centroDeEstudios}</Text>
        </View>
        <Text style={styles.title}>Receta médica</Text>
        <View style={styles.table}>
          <Text>{timestampToString(consulta.date)}</Text>
        </View>
        <View style={styles.tableInfo}>
          <View style={styles.tableDetails}>
            <Text style={styles.names}> {consulta.paciente.name}</Text>
            <Text style={styles.names}>
              {timestampToString(consulta.paciente.fechaNacimiento)} -{" "}
              {consulta.paciente.edad} años
            </Text>
            <Text>{consulta?.folio}</Text>
          </View>
          <View style={styles.tablesSigns}>
            <View style={styles.tableDetails}>
              <Text>Temp.:&nbsp;{consulta.signos.temperatura} °C </Text>
              <Text>Oximetría:&nbsp;{consulta.signos.oximetria}%</Text>
              <Text>
                Frec. Resp.:&nbsp;{consulta.signos.frecuencia_respiratoria} Rpm
                Fr/m
              </Text>
              <Text>
                Frec. Card.:&nbsp;{consulta.signos.frecuencia_cardiaca} Lpm Fc
              </Text>
              <Text>Peso:&nbsp;{consulta.signos.peso}Kgs</Text>
              <Text>Talla:&nbsp;{consulta.signos.talla} Mts</Text>
              <Text>IMC:&nbsp;{consulta.signos.imc.toFixed(2)} Kg/m²</Text>
            </View>
            <View style={styles.tableDetails}>
              <Text>
                Presión arterial:&nbsp;{consulta.signos.tension_arterial} Mmhg
              </Text>
             
            </View>
          </View>

          <View style={styles.tableContent}>
            <Text style={styles.subtitle}>Diagnóstico:</Text>
            <Text>{diagnostico}</Text>
            <Text style={styles.subtitle}>Tratamiento:</Text>
            {/* <Text>{tratamiento}</Text> */}
            <Text>{treatment}</Text>
            <Text style={styles.subtitle}>Recomendaciones:</Text>
            <Text>{recomendaciones}</Text>
            {laboratorio.length ? (
              <>
                <Text style={styles.subtitle}> Estudios de laboratorio:</Text>
                <Text> {lab} </Text>
              </>
            ) : null}
          </View>

          {/* <View style={styles.tableDetails}>
            {laboratorio.length ? <Text> Estudios de laboratorio:</Text> : null}
            {lab}
          </View> */}
        </View>

        <View style={styles.footer}>
          <View style={styles.signature}>
            <Svg height={10}>
              <Line
                color="#093046"
                stroke="4"
                fill="#093046"
                strokeWidth="3"
                visibility="visible"
                x1="160" //Punto inicial en x
                y1="0" //Punto inicial en y
                x2="380" //punto final en x
                y2="0" //Punto final en y
              />
            </Svg>
            <Text>
              Dr. <DoctorAsign docReference={consulta.Doctor} />
            </Text>
            <Text>Medicina General</Text>
            <Text>Cédula Profesional {doctorDetails?.cedula}</Text>
            <Text>{doctorDetails?.centroDeEstudios}</Text>
          </View>
          <Text style={{ marginLeft: 20, marginTop: 10 }}>
            Fray Pedro de Gante Norte #320,
          </Text>
          <Text style={{ marginLeft: 20 }}>56100,Centro, Texcoco México</Text>
          <Text style={{ marginLeft: 20 }}>595 954 2917,595 957 0961</Text>
        </View>
        {"\n"}
      </Page>
    </Document>
  );
};
