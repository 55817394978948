import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  styled,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";
import { Visibility, VisibilityOff, LoginOutlined } from "@mui/icons-material";
import { DotPulse } from "@uiball/loaders";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router";
import firebase from "../firebase";
import { searchUser } from "../contexts/Auth/controllers/searchUser";

const Login = () => {
  const { login, isLoading } = useAuth();
  const navigate = useNavigate();

  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    firebase.auth.onAuthStateChanged((user) => {
      if (user) {
        searchUser(user.uid).then((resp) => {
          if (!resp) return;
          navigate(`/${resp.area}`, { replace: true });
          console.log(resp);
        });
      }
    });
  }, []);
  return (
    <>
      <Grid container style={styles.image}>
        <Grid item xs={12} md={6} xl={6} style={styles.transbox}>
          <br />
          <br />
          <CssTextField
            fullWidth
            required
            label="Correo electrónico"
            id="input-email"
            type={"email"}
            value={values.email}
            onKeyDown={({ code, target }) => {
              if (code === "Enter") {
                //onSearchUser(target);
              }
            }}
            onChange={(value) =>
              setValues({
                ...values,
                email: value.target.value,
              })
            }
          />
          <br />
          <br />
          <CssTextField
            fullWidth
            required
            label="Contraseña"
            id="input-password"
            type={values.showPassword ? "text" : "password"}
            value={values.password}
            onChange={handleChange("password")}
            onKeyDown={({ code }) => {
              if (code === "Enter")
                login(values.email, values.password).then((area) =>
                  area ? navigate(`/${area}`, { replace: true }) : null
                );
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <br />
          <br />
          {isLoading ? (
            <div style={styles.center}>
              <DotPulse size={40} color="#0b2f89" />
            </div>
          ) : (
            <div style={styles.center}>
              <Button
                startIcon={<LoginOutlined />}
                onClick={() =>
                  login(values.email, values.password).then((area) => {
                    if (area) navigate(`/${area}`, { replace: true });
                  })
                }
                size="large"
                variant="outlined"
                style={styles.button}
              >
                Ingresar
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Login;

const styles = {
  image: {
    position: "relative",
    height: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: "url(https://movicaremx.com/HFUTURA/Login.png)",
    backgroundSize: "cover",
  },
  transbox: {
    // margin: "10% 10% 20px 50%",
    marginLeft: "10%",
    marginRight: "10%",
    backgroundColor: "#FFF",
    boxShadow: "5px 5px 15px #b4b4b4",
    width: "50%",
    padding: 10,
  },
  center: {
    margin: 15,
    marginLeft: "40%",
  },
};

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#093046",
      width: "100%",
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 10,
      borderBottomRightRadius: 10,
      borderTopRightRadius: 0,
    },
    "&:hover fieldset": {
      borderColor: "yellow",
    },
    "&.Mui-focused fieldset": {
      borderColor: "green",
    },
    "&.Mui-error fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
  },
});
