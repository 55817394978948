export const ServiceDate = ({ date = 0 }) => {
  try {
    if (!date) return <p style={{ color: "#9e0f02" }}>Sin seguimiento 🥺</p>;
    const fecha = new Date(date);

    const day = fecha.getDate();
    const month = fecha.getMonth() + 1;
    const year = fecha.getFullYear();
    const hour = fecha.getHours();
    const minutes = fecha.getMinutes();

    return `${day < 10 ? "0" + day : day}/ ${
      month < 10 ? "0" + month : month
    } / ${year} a las ${hour < 10 ? "0" + hour : hour}:${
      minutes < 10 ? "0" + minutes : minutes
    }`;
  } catch (error) {
    return "_err";
  }
};
