/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import ErrorAnimation from "../../Animated/403.json";
import { Grid } from "@mui/material";

const Error403 = () => {
  //::::::::::::::Tamaño Imagenes::::::::::::::::::::.
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };
  const [widthIMG, setWidthIMG] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWidthIMG(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  //:::::::::::::::::::::::::::::::::::::::::::::::::

  return (
    <>
      <Grid container className={styles.root}>
        <Grid xs={12} md={4} xl={4}></Grid>
        <Grid xs={12} md={4} xl={4}>
          <marquee
            direction="left"
            height="70"
            behavior="scroll"
            style={styles.title}
          >
            🚨 ¡Alto ahí! 🚨 <br /> No contienes accesos
          </marquee>
        </Grid>
        <Grid xs={12} md={4} xl={4}></Grid>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: ErrorAnimation,
          }}
          style={{
            width: widthIMG.width < 1000 ? "90%" : "35%",
            height: widthIMG.height < 1000 ? "20%" : "20%",
          }}
        />
      </Grid>
    </>
  );
};
export default Error403;

const styles = {
  root: {
    textAlign: "center",
    opacity: 0.6,
    filter: "grayscale(60%)",
    backgroundColor: "#FFF",
  },
  title: {
    textAlign: "center",
    marginTop: 80,
    fontWeight: "bold",
    marginLeft: "3%",
    color: "#000",
    fontSize: 25,
  },
};
