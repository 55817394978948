import { Autocomplete, styled, TextField } from "@mui/material";
import { useRef, useState } from "react";
import { cie10API } from "../APIS/CIE10";

export const SearchCIE10 = ({ onSelect = () => {} }) => {
  const searchInput = useRef(null);

  const [inputValue, setInputValue] = useState(null);
  const [cie10, setCie10] = useState([]);
  const [loading, setLoading] = useState(false);
  const debaunceRef = useRef(null);

  const handleChange = (e) => {
    if (debaunceRef.current) {
      clearTimeout(debaunceRef.current);
    }
    // console.log(e.target.value);
    debaunceRef.current = setTimeout(() => {
      const value = e.target.value || "";
      setLoading(true);
      cie10API
        .get(`/cie10/${e.target.value}`)
        .then((r) => {
          const { error, data } = r.data;
          if (error) {
            alert("cannot get cie10 from MoviCare");
            return;
          }
          console.log(data);
          setCie10(
            data
              .map((e, idx) => ({ ...e, key: idx }))
              .sort((a, b) => {
                const nameA = parseInt(a.CODIGO_SUBGRUPO.split(".")[1] || 0); // ignore upper and lowercase
                const nameB = parseInt(b.CODIGO_SUBGRUPO.split(".")[1] || 0); // ignore upper and lowercase

                console.log(nameA, nameB);
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }

                // names must be equal
                return 0;
              })
          );
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    }, 1000);
  };

  const handleEnter = () => {
    if (!inputValue) return;
    onSelect(inputValue);
    const ele = searchInput.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];

    if (ele) ele.click();
    setInputValue(null);
  };

  return (
    <Autocomplete
      ref={searchInput}
      id="search-cie10"
      options={cie10}
      required
      getOptionLabel={(option) =>
        `${option.CODIGO_SUBGRUPO || option.Codigo} - ${
          option.Subgrupo || option.GRUPO
        }`
      }
      loading={loading}
      groupBy={(option) => option.GRUPO}
      onChange={(a, b) => {
        let mutation = b;
        delete mutation.key;
        setInputValue(mutation);
      }}
      onKeyDown={(e) => {
        if (e.code === "Enter") {
          handleEnter();
        }
      }}
      renderInput={(props) => (
        <CssTextField
          {...props}
          required
          ref={debaunceRef}
          label="CIE1O"
          onChange={handleChange}
        />
      )}
      renderGroup={(params) => (
        <li key={params.key}>
          <div
            style={{
              position: "sticky",
              top: "-8px",
              padding: "4px 10px",
              backgroundColor: "#C1C1C1",
            }}
          >
            {params.group}
          </div>
          <ul style={{ padding: 0, marginLeft: 5 }}>{params.children}</ul>
        </li>
      )}
    />
  );
};

const CssTextField = styled(TextField)({
  width: "90%",
  marginTop: 10,
  marginBottom: 10,
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 20,
    borderBottomRightRadius: 20,
    borderTopRightRadius: 0,
    borderWidth: 2,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#0b6e89",
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 20,
      borderBottomRightRadius: 20,
      borderTopRightRadius: 0,
      borderWidth: 2,
    },
    "&:hover fieldset": {
      borderColor: "yellow",
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 20,
      borderBottomRightRadius: 20,
      borderTopRightRadius: 0,
      borderWidth: 2,
    },
    "&.Mui-focused fieldset": {
      borderColor: "green",
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 20,
      borderBottomRightRadius: 20,
      borderTopRightRadius: 0,
      borderWidth: 2,
    },
  },
});
