import React, { Suspense, useContext, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { Pinwheel } from "@uiball/loaders";

//Proteccion de ruta ** validar el tipo de usuario**
import PrivateRoute from "../utils/PrivateRoute";

import { ProtectedRoute } from "./ProtectedRoutes";

// Páginas de errores
import Error403 from "../Views/Errors/Error403";
import Error404 from "../Views/Errors/Error404";
import Error503 from "../Views/Errors/Error503";
import Login from "../Views/Login";
import Redirection from "./Redirect";
import Consulta from "../03-DOCTORS/Views/Consulta";
import { Uploads } from "../Views/Uploads";
import { Downloads } from "../Views/Downloads";
import NewUsers from "../Views/NewUsers";
import Reportes from "../01-Recepcion/Components/ExportResume";
import UpdateAlgolia from "../Views/UpateAlgolia";
import CreatePetitionItem from "../Views/CreatePetitionItems";

/**
 * LazyLoad route handlers
 */
const LazyRecepcion = lazy(() =>
  import(
    /* webpackChunkName: "RecepcionChunk"*/ "../01-Recepcion/RecepcionNavigate"
  )
);

const LazyNursing = lazy(() =>
  import(/* webpackChunkName: "NursingChunk"*/ "../02-NURSING/NursingNavigate")
);

const LazyDoctors = lazy(() =>
  import(/* webpackChunkName: "DoctorsChunk"*/ "../03-DOCTORS/DoctorsNavigate")
);

const LazyManager = lazy(() =>
  import(/* webpackChunkName: "ManagerChunk"*/ "../04-MANAGER/ManagerNavigate")
);

const LazyStorer = lazy(() =>
  import(/* webpackChunkName: "ManagerChunk"*/ "../05-STORE/StorerNavigate")
);

const LazyPharmacy = lazy(() =>
  import(/* webpackChunkName: "ManagerChunk"*/ "../06-PHARMACY/PharmacyNavigate")
);

const Navigation = () => {
  return (
    <Suspense
      fallback={
        <div style={{ marginTop: "20%", marginLeft: "45%" }}>
          <Pinwheel size={80} color="#004b80" />
        </div>
      }
    >
      <Routes>
        <Route
          path="/Recepcion/*"
          element={
            <ProtectedRoute>
              <LazyRecepcion />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<Redirection />} />
        {/* Páginas de errores */}
        <Route path="*" element={<Error404 />} />
        <Route path="/Error403" element={<Error403 />} />
        <Route path="/Error503" element={<Error503 />} />

        <Route path="/Login" element={<Login />} />
        <Route
          path="/Nursing/*"
          element={
            <ProtectedRoute>
              <LazyNursing />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Doctors/*"
          element={
            <ProtectedRoute>
              <LazyDoctors />
            </ProtectedRoute>
          }
        />
        <Route path="/Consulta" element={<Consulta />} />
        <Route
          path="/Manager/*"
          element={
            // <ProtectedRoute>
            <LazyManager />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/Storer/*"
          element={
            // <ProtectedRoute>
            <LazyStorer />
            // </ProtectedRoute>
          }
        />

        <Route
          path="/Pharmacy/*"
          element={
            // <ProtectedRoute>
            <LazyPharmacy />
            // </ProtectedRoute>
          }
        />

        <Route path="/Uploads" element={<Uploads />} />
        <Route path="/Downloads" element={<Downloads />} />
        <Route path="/NewUsers" element={<NewUsers />} />
        <Route path="/Reportes" element={<Reportes />} />
        <Route path="/CreatePetition" element={<CreatePetitionItem />} />

        <Route path="/UpdateAlgolia" element={<UpdateAlgolia />} />
      </Routes>

      <p style={{ marginTop: "13%", marginLeft: "3%", textAlign: "center" }}>
        <b> {" " + process.env.REACT_APP_VERSION} v </b> <br />
        OPERADORA HOSPITAL FUTURA S.A. de C.V. <br />
        COPYRIGHT © TODOS LOS DERECHOS RESERVADOS
      </p>
    </Suspense>
  );
};

export default Navigation;
