import { useEffect, useState } from "react";
import { CssAutcomplete } from "../../Components/CustomAutocomplete";
import firebase from "../../firebase";
import { TextField } from "@mui/material";
const CatalogoComponent = ({ onSelect = () => {}, onEnter = () => {} }) => {
  const [options, setOptions] = useState([]);
  const getCatalogo = async () => {
    const response = await firebase.db
      .collection("CatalogoAux")
      .orderBy("nombre")
      .get();
    return response.docs.map((doc) => {
      return { ...doc.data(), id: doc.id, ref: doc.ref };
    });
  };

  useEffect(() => {
    getCatalogo().then(setOptions);
  }, []);

  return (
    <CssAutcomplete
      options={options}
      onChange={(_, value) => {
        onSelect(value || null);
      }}
      getOptionLabel={(option) => option.nombre + " - " + option?.categoria}
      renderInput={(params) => <TextField {...params} label="Catalogo" />}
      onKeyDown={(e) => {
        if (e.code.toUpperCase().includes("ENTER")) {
          onEnter();
        }
      }}
    />
  );
};

export default CatalogoComponent;
