import React, { useEffect, useState } from "react";
import algoliasearch from "algoliasearch";
import { CssAutcomplete } from "../../Components/CustomAutocomplete";
import { TextField } from "@mui/material";
import { CssTextField } from "../../Components/CustomTextField";

const client = algoliasearch("CJ6E1JY7AY", "26a12b04b125baa9cda4969b51a6fd06");
const index = client.initIndex("CATALOGO");
const SearchItems = ({ onSelectValue = () => {} }) => {
  const [selected, setSelected] = useState(null);
  const [results, setResults] = useState([]);

  const performSearch = async (value) => {
    const { hits } = await index.search(value, {
      // hitsPerPage: 15,
    });

    const results = hits.map((hit) => {
      const { objectID, ...rest } = hit;

      return { id: objectID, ...rest };
    });
    setResults(results);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setSelected(e.target.value);
    !value ? setResults([]) : performSearch(value);
  };
  return (
    <div>
      <CssTextField
        placeholder="Buscar artículos"
        value={selected ?? null}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField {...params} label={"Buscar artículos"} />
        )}
      />
      <div
        style={{
          zIndex: 3,
          position: "absolute",
          backgroundColor: "#FFF",
          width: "40%",
          maxHeight: "60%",
          overflowY: "scroll",
          marginLeft: "1%",
          marginTop: -8,
          boxShadow: "5px 5px 15px #b4b4b4",
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20,
        }}
      >
        {results.map(({ nombre, descripcion, sustanciaActiva, ...doc }) => {
          return (
            <>
              <p
                style={{ fontWeight: "bold", marginLeft: 20 }}
                onClick={() => {
                  onSelectValue({ nombre, descripcion, ...doc });
                  setSelected(nombre);
                  setResults([]);
                }}
              >
                {nombre} {sustanciaActiva ? `- ${sustanciaActiva}` : null}
                <p style={{ fontSize: 10, fontWeight: "normal" }}>
                  {descripcion}
                </p>
              </p>
              <hr style={{ border: "1px dashed #0b6e89", width: "90%" }} />
            </>
          );
        })}
      </div>
    </div>
  );
};

export default SearchItems;
