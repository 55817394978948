import "./App.css";
import Navigation from "./routes/Navigation";
import AuthState from "./contexts/Auth/authState";
import { SnackbarProvider } from "notistack";
import { ThemeProvider, createTheme } from "@mui/material";
import { esES } from "@mui/material/locale";

const App = () => {

  const theme = createTheme(
    esES
  );

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <AuthState>
          <Navigation />
        </AuthState>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
