import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TableCell,
tableCellClasses
} from "@mui/material";
import { useSnackbar } from "notistack";
import { DoctorAsign } from "../../Components/DoctorAsign";
import timestamp_toString from "../../utils/timestamp_toString";
import { useDoctors } from "../hooks/useDoctors";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { RecetaTemplate } from "../../templates/Receta";
import { useHistory } from "../hooks/useHistory";

const History = () => {
  const { consulta } = useDoctors();
  const { enqueueSnackbar } = useSnackbar();
  const { setSelectedConsulta } = useHistory();
  const [loading] = useState(false); //muestra de skeleton
  const [error] = useState(false); //Utilizar impresión de manejo de errores

  const [consultas, setConsultas] = useState([]);
  const get_History = async () => {
    try {
      consulta.paciente?.ref
        .collection("Consultas")
        .orderBy("date", "desc")
        .get()
        .then((response) => {
          const consultas = response.docs.map(async (doc) => {
            const px = await doc.ref.parent.parent.get();
            return {
              ...doc.data(),
              paciente: { ...px.data(), id: px.id, ref: px.ref },
              id: doc.id,
              ref: doc.ref,
            };
          });

          Promise.all(consultas)
            .then(setConsultas)
            .then(() => null)
            .catch(() => alert("Catch"));
        });
    } catch (error) {
      // alert("UnDone!")
      console.log(error);
    }
  };
  useEffect(() => {
    if (consulta?.paciente) {
      get_History();
    }
  }, [consulta]);
  const [form, setForm] = useState({
    Historico: {
      alergia: "",
      descripcion: "",
    },
  });

  //   FECHA DE BÚSQUEDA init

  //  today.setHours(23, 59, 0, 0);
  //  today1.setHours(0, 0, 0, 0);
  const [dateSelected, setDate] = useState(true);

  const handleSelectedDay = ({ target: { value } }) => {
    const f = value.split("-");
    const fechaBusqueda = new Date(f[0], parseInt(f[1]) - 1, f[2]);
    const date1 = fechaBusqueda.setHours(0, 0, 0, 0);
    setDate([new Date(date1)]);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} xl={6}>
          {/* {loading ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={40}
              height={40}
            />
          ) : (
            <CssTextField
              label="Fecha de consulta"
              type="date"
              required="true"
              value={timestamp_toString(dateSelected[0])}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={handleSelectedDay}
            />
          )} */}
        </Grid>
        <Grid item xs={12} md={12} xl={12}>
          <TableContainer>
            <Table sx={{ margin: 1, width: "93%" }} size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Fecha</StyledTableCell>
                  <StyledTableCell>Médico</StyledTableCell>
                  <StyledTableCell>Motivo</StyledTableCell>
                  <StyledTableCell>Receta</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {consultas.map((data) => {
                  const { PSOAP, ...rest } = data;
                  const newData = {
                    ...PSOAP,
                    consulta: rest,
                  };

                  return (
                    <CustomRow
                      onClick={() => {
                        if (data.PSOAP) {
                          setSelectedConsulta(data);
                        } else {
                          setSelectedConsulta(null);
                          enqueueSnackbar(
                            "No pudimos recuperar PSOAP de esta consulta",
                            { variant: "warning" }
                          );
                        }
                      }}
                      fecha={rest.date}
                      doctor={data.Doctor}
                      motivo={data.motivo}
                      isCurrent={data.id === consulta.id}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <p style={{ textAlign: "center" }}>
            Filas totales: {consultas.length} - 10
          </p>
        </Grid>
      </Grid>
    </>
  );
};

const CustomRow = ({
  fecha,
  doctor,
  motivo = "...",
  onClick = () => {},
  isCurrent = false,
}) => {
  return (
    <StyledTableRow
      onClick={onClick}
      style={{
        backgroundColor: isCurrent ? "#e6e6fa" : null,
        color: isCurrent ? "#FFF" : null,
      }}
    >
      <TableCell>{timestamp_toString(fecha)}</TableCell>
      <TableCell>
        <DoctorAsign docReference={doctor} />
      </TableCell>
      <TableCell>{motivo}</TableCell>
      <TableCell>
        <Button variant="outlined" disabled>
          Download
        </Button>
      </TableCell>
    </StyledTableRow>
  );
};
export default History;

const design = {
  button: {
    marginLeft: "20%",
    marginTop: 15,
    marginBottom: 10,
    width: "60%",
  },
  center: {
    marginTop: 20,
    marginLeft: "40%",
  },
  icon: {
    color: "#7e0c01",
  },
};

const CssTextField = styled(TextField)({
  width: "90%",
  margin: 10,
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 20,
    borderBottomRightRadius: 20,
    borderTopRightRadius: 0,
    borderWidth: 2,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#0b6e89",
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 20,
      borderBottomRightRadius: 20,
      borderTopRightRadius: 0,
      borderWidth: 2,
    },
    "&:hover fieldset": {
      borderColor: "yellow",
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 20,
      borderBottomRightRadius: 20,
      borderTopRightRadius: 0,
      borderWidth: 2,
    },
    "&.Mui-focused fieldset": {
      borderColor: "green",
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 20,
      borderBottomRightRadius: 20,
      borderTopRightRadius: 0,
      borderWidth: 2,
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#260b89",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
