import { Autocomplete, styled } from "@mui/material";

export const CssAutcomplete = styled(Autocomplete)({
    width: "90%",
    margin: 10,
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 20,
      borderBottomRightRadius: 20,
      borderTopRightRadius: 0,
      borderWidth: 3,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#0b6e89",
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 20,
        borderBottomRightRadius: 20,
        borderTopRightRadius: 0,
        borderWidth: 3,
      },
      "&:hover fieldset": {
        borderColor: "yellow",
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 20,
        borderBottomRightRadius: 20,
        borderTopRightRadius: 0,
        borderWidth: 3,
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 20,
        borderBottomRightRadius: 20,
        borderTopRightRadius: 0,
        borderWidth: 3,
      },
    },
  });