import { useEffect, useState } from "react";

export const ResponsableInfo = ({ docReference = null }) => {
  const [name, setName] = useState(null);
  const [loading, setLoadign] = useState(false);

  useEffect(() => {
    try {
      if (!docReference && !loading) {
        setName("");
        setLoadign(false);
        return;
      }
      docReference.get().then((response) => {
        if (!response.exists) {
          return;
        }

        const { DatosPersonales } = response.data();

        setName(DatosPersonales?.nombre);
        setLoadign(false);
      });
    } catch (error) {
      setName("nombre");
    }
  }, [docReference, loading]);
  if (loading) return "Loading...";
  if(!name) return 'No se enuentra el registro'
  return name;
};
