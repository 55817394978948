import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Autocomplete,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { DotPulse } from "@uiball/loaders";
import { Delete } from "@mui/icons-material";
import { useDoctors } from "../hooks/useDoctors";
import { useSnackbar } from "notistack";
import { useAuth } from "../../hooks/useAuth";

const GeneralData = () => {
  const antecedentesRef = useRef(null);
  const detallesRef = useRef(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { consulta } = useDoctors();
  const { usuario } = useAuth();
  const [loading] = useState(false); //muestra de skeleton
  const { error } = useState(false); //Utilizar impresión de manejo de errores

  // Antecedentes Section
  const [loadingA, setLoadingA] = useState(false);
  const [formAntecedente, setAntecedente] = useState({
    detalle: "",
    descripcion: "",
    antecedente: "",
  });
  const [antecedentes, setAntecedentes] = useState([]);

  const add_Antecedente = async () => {
    setLoadingA(true);
    try {
      if (!formAntecedente.antecedente) {
        throw new Error("Selecciona un antecedente");
      }
      if (!formAntecedente.descripcion) {
        throw new Error("Define una descripcion para el antecedente");
      }
      if (!formAntecedente.detalle) {
        throw new Error("Selecciona un detalle");
      }
      await consulta.paciente.ref.collection("Antecedentes").add({
        date: new Date(),
        ...formAntecedente,
        respnsable: usuario.ref,
      });
      setLoadingA(false);
      setAntecedente({ detalle: "", descripcion: "", antecedente: "" });

      // ====================================
      const ele = antecedentesRef.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];

      if (ele) ele.click();
      // ====================================
      const ele1 = detallesRef.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];

      if (ele1) ele1.click();
    } catch (error) {
      setLoadingA(false);
      enqueueSnackbar(error.message, {
        variant: "warning",
        preventDuplicate: true,
      });
    }
  };

  const getAntecedentes = async () => {
    return new Promise((resolve) => {
      consulta.paciente.ref
        .collection("Antecedentes")
        .orderBy("date", "desc")
        .onSnapshot((response) => {
          const antecedentes = response.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
              ref: doc.ref,
            };
          });
          //
          setAntecedentes(antecedentes);
          resolve();
        });
    });
  };
  useEffect(() => {
    getAntecedentes();
  }, []);

  // Alergias Section
  const [loadingAl, setLoadingAl] = useState(false);
  const [formAlergia, setAlergia] = useState({
    detalle: "",
    descripcion: "",
  });
  const [alergias, setAlergias] = useState([]);

  const add_Alergia = async () => {
    setLoadingAl(true);
    try {
      await consulta.paciente.ref.collection("Alergias").add({
        date: new Date(),
        ...formAlergia,
        respnsable: usuario.ref,
      });
      setLoadingAl(false);
      setAlergia({ alergia: "", descripcion: "" });
    } catch (error) {
      setLoadingAl(false);
      enqueueSnackbar(error.message, {
        variant: "warning",
        preventDuplicate: true,
      });
    }
  };

  const getAlergias = async () => {
    return new Promise((resolve) => {
      consulta.paciente.ref
        .collection("Alergias")
        .orderBy("date", "desc")
        .onSnapshot((response) => {
          const alergias = response.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
              ref: doc.ref,
            };
          });
          //
          setAlergias(alergias);
          resolve();
        });
    });
  };

  useEffect(() => {
    getAlergias();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12} xl={12}>
          <h2>Antecedentes</h2>
        </Grid>

        <Grid item xs={12} md={3} xl={3}>
          <Autocomplete
            disablePortal
            id="Antecedentes"
            ref={antecedentesRef}
            options={antecedentesOpt}
            renderInput={(params) => (
              <CssTextField
                {...params}
                label="Categoría del antecedente"
                type="text"
                variant="outlined"
                helperText={error ? "Verificar información" : null}
              />
            )}
            onChange={(_, value) => {
              setAntecedente({
                ...formAntecedente,
                antecedente: value?.label || null,
                antecedenteKey: value?.key || null,
              });
            }}
          />
        </Grid>
        {formAntecedente.antecedente !== "Otro" ? (
          <>
            <Grid item xs={12} md={3} xl={3}>
              <Autocomplete
                disablePortal
                id="Detalle"
                ref={detallesRef}
                options={
                  subcategoriasList[formAntecedente?.antecedenteKey] || []
                }
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    label="Detalle"
                    type="text"
                    variant="outlined"
                    helperText={error ? "Verificar información" : null}
                  />
                )}
                onChange={(_, value) => {
                  setAntecedente({
                    ...formAntecedente,
                    detalle: value || null,
                  });
                }}
              />
            </Grid>
          </>
        ) : null}

        <Grid item xs={12} md={3} xl={3}>
          {loading ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={40}
              height={40}
            />
          ) : (
            <CssTextField
              label="Descripción"
              type="text"
              multiline
              maxRows={4}
              variant="outlined"
              value={formAntecedente.descripcion}
              helperText={error ? "Verificar información" : null}
              onChange={({ target }) => {
                setAntecedente({
                  ...formAntecedente,
                  descripcion: target?.value || null,
                });
              }}
            />
          )}
        </Grid>

        <Grid item xs={12} md={3} xl={3}>
          {loadingA ? (
            <div style={design.center}>
              <DotPulse size={40} color="#0b2f89" />
            </div>
          ) : (
            <Button
              variant="contained"
              style={design.button}
              onClick={add_Antecedente}
            >
              Agregar
            </Button>
          )}
        </Grid>

        <Grid item xs={12} md={12} xl={12}>
          <TableContainer>
            <Table
              sx={{
                marginLeft: 1,
                marginTop: 3,
                marginBottom: 3,
                width: "97%",
              }}
              size="small"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StyledTableCellAntecedentes>
                    Antecedente
                  </StyledTableCellAntecedentes>
                  <StyledTableCellAntecedentes>
                    Detalle
                  </StyledTableCellAntecedentes>
                  <StyledTableCellAntecedentes>
                    Descripción
                  </StyledTableCellAntecedentes>
                  <StyledTableCellAntecedentes>
                    Acción
                  </StyledTableCellAntecedentes>
                </TableRow>
              </TableHead>
              <TableBody>
                {antecedentes.map((data) => (
                  <StyledTableRow key={data.key}>
                    <StyledTableCell component="th" scope="data">
                      {data.antecedente}
                    </StyledTableCell>
                    <StyledTableCell>{data.detalle}</StyledTableCell>
                    <StyledTableCell>{data.descripcion}</StyledTableCell>
                    <StyledTableCell
                      onClick={() => {
                        enqueueSnackbar(
                          "¿Estas seguro que deseas eliminar este valor?",
                          {
                            variant: "default",
                            autoHideDuration: 1300,
                            action: () => {
                              return (
                                <Button
                                  style={design.boton}
                                  onClick={() => data.ref.delete()}
                                >
                                  Si, eliminar
                                </Button>
                              );
                            },
                          }
                        );
                      }}
                    >
                      <Delete fontSize="small" style={design.icon} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} md={12} xl={12}>
          <h2>Alergias</h2>
        </Grid>
        <Grid item xs={12} md={3} xl={3}>
          {loading ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={40}
              height={40}
            />
          ) : (
            <CssTextField
              label="Alergia"
              type="text"
              variant="outlined"
              helperText={error ? "Verificar información" : null}
              // value={form.Historico.alergia}
              onChange={({ target }) =>
                setAlergia({ ...formAlergia, alergia: target.value })
              }
            />
          )}
        </Grid>

        <Grid item xs={12} md={3} xl={3}>
          {loading ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={40}
              height={40}
            />
          ) : (
            <CssTextField
              label="Descripción"
              type="text"
              multiline
              maxRows={4}
              variant="outlined"
              helperText={error ? "Verificar información" : null}
              // value={form.Historico.descripcion}
              onChange={({ target }) =>
                setAlergia({ ...formAlergia, descripcion: target.value })
              }
            />
          )}
        </Grid>

        <Grid item xs={12} md={3} xl={3}>
          {loadingAl ? (
            <div style={design.center}>
              <DotPulse size={40} color="#0b2f89" />
            </div>
          ) : (
            <Button
              variant="contained"
              style={design.button}
              onClick={add_Alergia}
            >
              Agregar
            </Button>
          )}
        </Grid>

        <Grid item xs={12} md={12} xl={12}>
          <TableContainer>
            <Table
              sx={{
                marginLeft: 1,
                marginTop: 3,
                marginBottom: 3,
                width: "97%",
              }}
              size="small"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>Nombre</StyledTableCell>
                  <StyledTableCell>Descripción</StyledTableCell>
                  <StyledTableCell>Acción</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {alergias.map((data) => (
                  <StyledTableRow key={data.key}>
                    <StyledTableCell component="th" scope="data">
                      {data.alergia}
                    </StyledTableCell>
                    <StyledTableCell>{data.descripcion}</StyledTableCell>
                    <StyledTableCell
                      onClick={() => {
                        enqueueSnackbar(
                          "¿Estas seguro que deseas eliminar este valor?",
                          {
                            variant: "info",
                            autoHideDuration: 1300,
                            action: (idSnackbar) => {
                              return (
                                <Button
                                  onClick={() => {
                                    data.ref.delete();
                                    closeSnackbar(idSnackbar);
                                  }}
                                >
                                  Si, eliminar
                                </Button>
                              );
                            },
                          }
                        );
                      }}
                    >
                      <Delete fontSize="small" style={design.icon} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* {spin ? (
          <div style={design.center}>
            <DotPulse size={40} color="#0b2f89" />
          </div>
        ) : (
          <Button variant="contained" style={design.button}>
            Guardar
          </Button>
        )} */}
      </Grid>
    </>
  );
};

export default GeneralData;

const design = {
  button: {
    marginLeft: "20%",
    marginTop: 10,
    marginBottom: 10,
    width: "60%",
  },
  center: {
    // margin: 15,
    marginLeft: "47%",
    marginTop: "3%",
  },
  boton: {
    color: "#a73c01",
    fontSize: 12,
    textDecoration: "underline",
    fontWeight: "bold",
    marginTop: "-1%",
  },
};

const CssTextField = styled(TextField)({
  width: "90%",
  margin: 10,
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 20,
    borderBottomRightRadius: 20,
    borderTopRightRadius: 0,
    borderWidth: 2,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#0b6e89",
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 20,
      borderBottomRightRadius: 20,
      borderTopRightRadius: 0,
      borderWidth: 2,
    },
    "&:hover fieldset": {
      borderColor: "yellow",
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 20,
      borderBottomRightRadius: 20,
      borderTopRightRadius: 0,
      borderWidth: 2,
    },
    "&.Mui-focused fieldset": {
      borderColor: "green",
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 20,
      borderBottomRightRadius: 20,
      borderTopRightRadius: 0,
      borderWidth: 2,
    },
  },
});

const antecedentesOpt = [
  { key: "Heredofamiliares", label: "Heredero -  familiares" },
  { key: "PersonalesPatologicos", label: "Personales patológicos" },
  { key: "PersonalesNoPatologicos", label: "Personales no patológicos" },
  { key: "Ginecoobstretricos", label: "Ginecólogicos" },
  // { key: "Perinatales", label: "Perinatales" },
  { key: "Otro", label: "Otro" },
];

const subcategoriasList = {
  Heredofamiliares: [
    "HIPERTENSIÓN",
    "DIABETES",
    "RENALES",
    "PULMONARES",
    "CARDIOVASCULARES",
    "ONCOLÓGICAS",
    "HEMATOLÓGICAS",
    "OTROS",
  ],
  PersonalesPatologicos: [
    "INFECTOCONTAGIOSA",
    "CRONODEGENERATIVO",
    "QUIRURGICOS",
    "TRAUMATICOS",
    "HOSPITALIZACIONES",
    "TRANSFUNSIONALES",
    "HIPERTENSIÓN",
    "DIABETES",
    "RENALES",
    "PULMONARES",
    "CARDIOVASCULARES",
    "ONCOLÓGICAS",
    "HEMATOLÓGICAS",
    "ALERGICOS",
    "OTROS",
  ],
  PersonalesNoPatologicos: [
    "VIVIENDA",
    "HÁBITOS HIGIENICODIETETICOS",
    "TOXICOMANIAS O TABAQUISMO",
    "ESTADO CIVIL",
    "RELIGIÓN",
    "OCUPACIÓN",
    "VACUNACIÓN",
    "ESCOLARIDAD",
    "GRUPO SANGUÍNEO",
    "ZOONOSIS",
    "OTROS",
  ],
  Ginecoobstretricos: [
    "MENARCA",
    "TELARCA",
    "IVSA (INICIO DE VIDA SEXUAL ACTIVA)",
    "GESTAS (CESAREA,PARTO,ABORTO)",
    "FUM (FECHA DE ÚLTIMA MENSTRUACIÓN)",
    "MPF (MÉTODO DE PLANIFICACIÓN FAMILIAR)",
    "OTROS",
  ],
};
const detalle = [
  { key: 0, label: "Hipertensión arterial sistemica" },
  { key: 1, label: "Abetalipoproteinemia" },
  { key: 2, label: "Ácido úrico artristismo" },
  { key: 3, label: "Alcoholismo" },
  { key: 4, label: "Anemia" },
  { key: 5, label: "Displipidemia" },
  { key: 6, label: "Sobrepeso / obesidad" },
  { key: 7, label: "Diabetes Mellitus I" },
  { key: 8, label: "Diabetes Mellitus II" },
  { key: 8, label: "Insuficiencia renal crónica" },
  { key: 9, label: "Neumopatía obstructiva crónica" },
  { key: 10, label: "Insuficiencia cardíaca" },
  { key: 11, label: "Osteoartritis" },
  { key: 12, label: "Insuficiencia venosa Periferica" },
  { key: 13, label: "Hipotiroidismo" },
  { key: 14, label: "Artritis reumatoide" },
  { key: 15, label: "Hiperuricemia" },
  { key: 16, label: "Hipercolesterolemia" },
  { key: 17, label: "Otro" },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#260b89",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCellAntecedentes = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#6e890b",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
