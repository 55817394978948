import { Skeleton, Grid } from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDoctors } from "../hooks/useDoctors";
import { NurseAsign } from "../../Components/NurseAsign";

const Signs = () => {
  const { consulta, isLoading } = useDoctors();
  const [doc, setDoc] = useState({
    temperatura: 0,
    tension_arterial: 0,
    peso: 0,
    talla: 0,
    imc: 0,
    frecuencia_cardiaca: 0,
    frecuencia_respiratoria: 0,
    centimetros_cintura: 0,
    centimetros_cadera: 0,
    glucosa_capilar: 0,
    oximetria: 0,
    nota: "",
    motivo: "Dolor estomacal",
    enfermera: "Leticia",
    // condicion: "", //AMBULANTE, ENCAMADO
    // actitud: "", //libremente escogida, instintiva, forzada, pasiva
    // conformacion: "", // biotipología sheldon
    // exploracionfisica: "",
    // movimientosAnormales: "", //Temblores,convulsiones,TICS,, etc..
    // conciencia: "", //Consiente, somnolencia, confusión, obnubilación, estupor, sopor, coma, muerte cerebral
  });

  useLayoutEffect(() => {
    setDoc(consulta.signos);
  }, []);

  if (isLoading)
    return (
      <Skeleton
        animation="wave"
        variant="rounded"
        width={400}
        height={40}
        style={{ marginBottom: 20 }}
      />
    );

  return (
    <>
      <Grid container style={design.Tarjeta}>
        <Grid xs={12} md={12} xl={12}>
          <h2 id="folio" style={design.Titulo}>
            Signos
          </h2>
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <p style={design.texto}>
            <strong>Temperatura:</strong>{" "}
            {doc?.temperatura + " ºC" || "Error al obtener"} <br />
            <strong>Tensión arterial:</strong>{" "}
            {doc?.tension_arterial + " Mmhg" || "Error al obtener"} <br />
            <strong>Frecuencia cardiaca:</strong>{" "}
            {doc?.frecuencia_cardiaca + " Lpm Fc" || "Error al obtener"} <br />
            <strong>Frecuencia respiratoria:</strong>{" "}
            {doc?.frecuencia_respiratoria + " Rpm Fr/m" || "Error al obtener"} <br />
            <strong>Oximetría:</strong>{" "}
            {doc?.oximetria + " %" || "Error al obtener"}<br />
            <strong>Enfermera: &nbsp; </strong>
            <NurseAsign docReference={consulta?.enfermera} />
          </p>
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <p style={design.texto}>
            <strong>Peso:</strong> {doc?.peso + " Kg." || "Error al obtener"} <br />
            <strong>Talla:</strong> {doc?.talla + " Mts" || "Error al obtener"} <br />
            <strong>IMC:</strong> {doc?.imc.toFixed(2) + " Kg/m²" || "Error al obtener"}<br />
            <strong>Cintura:</strong>{" "}
            {doc?.centimetros_cintura + " Cm" || "Error al obtener"}<br />
            <strong>Cadera:</strong>{" "}
            {doc?.centimetros_cadera + " Cm" || "Error al obtener"}<br />
            <strong>Glucosa capilar:</strong>{" "}
            {doc?.glucosa_capilar + "mg/dl" || "Error al obtener"}
          </p>
        </Grid>
        <Grid item xs={12} md={12} xl={12}>
          <p style={design.texto}>
            <strong>Motivo de consulta:</strong>{" "}
            {consulta?.motivo || "Error al obtener"} <br />
            <strong>Nota:</strong> {doc?.nota || "Nota no capturada."}
          </p>
        </Grid>
      </Grid>
    </>
  );
};

export default Signs;

const design = {
  Tarjeta: {
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "5px 5px 15px #b4b4b4",
    border: "1px solid #0b6e89",
    borderRadius: 15,
    padding: 10,
    marginTop: "3%",
    marginBottom: "3%",
    marginLeft: "5%",
    width: "90%",
  },
  clip: {
    height: "20px",
    borderRadius: 5,
    marginTop: -10,
    backgroundColor: "#0b6e89",
  },
  Titulo: {
    fontSize: 28,
    color: "#0b6e89",
    textAlign: "center",
  },
};
