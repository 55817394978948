import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import DATA from "../Views/Instituciones.json";
import firebase from "../firebase";
export const Uploads = () => {
  const [count, setCount] = useState(0);

  const [uploads, setUploads] = useState([]);
  const getConsultas= async()=>{
    const response = await firebase.db.collectionGroup('Consultas').get()

    console.log(response.size)
    alert(response.size)

    response.docs.forEach(async(doc, index)=>{  
      await doc.ref.delete()
      console.log(index)
    })
  }

  const upload = () => {
    // setCount(0);
    // DATA.forEach((inst) => {
    //   // const { ID, NOMBRE, TELEFONO } = inst;

    //   // const price = PRECIO.replace(".", "").replace(",", ".");
    //   // const newItem = {
    //   //   NOMBRE,
    //   //   TELEFONO,
    //   // };

    // //   firebase.db
    // //     .collection("Instituciones")
    // //     .add(newItem)
    // //     .then(() => {
    // //       setUploads((prev) => [...prev, newItem]);
    // //       setCount((prev) => prev + 1);
    // //     });
    // });
  };

  return (
    <>
    <Button onClick={getConsultas}>Delete</Button>
    </>
  )
  return (
    <div>
      Uploads <b>{count}</b>
      <Button variant="outlined" onClick={upload}>
        Upload
      </Button>
      {/* list items */}
      <nav aria-label="secondary mailbox folders">
        <List>
          {uploads.map((item, idx) => {
            return (
              <ListItem disablePadding>
                <p style={{ textAlign: "center", flex: 1 }}>
                  #{idx} - ${item.ID}
                </p>
                <Divider />
                <ListItemButton>
                  <ListItemText
                    primary={item.NOMBRE}
                    secondary={item.TELEFONO}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </nav>
    </div>
  );
};
