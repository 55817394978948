import {
  AUTH_USER,
  SET_CONSULTAS,
  SET_LOADING,
  USER_DATA,
  USER_LOGIN,
  USER_LOGOUT,
} from "./types";

export default (state, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return { ...state, user: action.payload };
    case USER_DATA:
      return { ...state, usuario: action.payload };
    case USER_LOGOUT:
      return { ...state, user: null, usuario: null };
    case SET_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_CONSULTAS:
      return { ...state, consultas: action.payload };
    case "CONSULTAS_COUNT":
      return { ...state, consultas_Count: action.payload };
    default:
      return state;
  }
};
