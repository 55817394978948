import { Button, MenuItem, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import firebase from "../firebase";
import { useSnackbar } from "notistack";
const NewUsers = () => {
  const [form, setForm] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const addUser = async () => {
    try {
      const { correo, nombre, apellidos, area } = form;
      const user = await firebase.auth.createUserWithEmailAndPassword(
        correo,
        "MoviCare10"
      );

      await firebase.db
        .collection("Usuarios")
        .doc(user.user.uid)
        .set({
          DatosPersonales: { nombre: `${nombre} ${apellidos}`, correo },
          area,
        });
      enqueueSnackbar("Usuario registrado correctamente", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <div style={{ marginTop: 50 }}>
      <TextField
        onChange={(e) => {
          setForm((prev) => ({ ...prev, correo: e.target.value }));
        }}
        placeholder="Correo"
      />
      <TextField
        onChange={(e) => {
          setForm((prev) => ({ ...prev, nombre: e.target.value }));
        }}
        placeholder="Nombre"
      />
      <TextField
        onChange={(e) => {
          setForm((prev) => ({ ...prev, apellidos: e.target.value }));
        }}
        placeholder="Apellidos"
      />
      <Select
        label="Area"
        onChange={(e) => {
          setForm((prev) => ({ ...prev, area: e.target.value }));
          //   setForm(prev > { ...prev, correo: e.target.value });
        }}
      >
        <MenuItem value="Nursing">Enfermeria</MenuItem>
        <MenuItem value="Doctors">Doctores</MenuItem>
        <MenuItem value="Recepcion">Recepción</MenuItem>
      </Select>

      <Button onClick={addUser}>Agregar usuario</Button>
    </div>
  );
};

export default NewUsers;
